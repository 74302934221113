import axios from "axios";

const BASE_URL =
  "http://classbuddyapi-dev-dev.eba-fa34u9vw.us-east-1.elasticbeanstalk.com/"; // base url path
export default axios.create({ baseURL: BASE_URL });

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});
