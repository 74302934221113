import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={convertGpaToPercentage(props.value)}
        size={130}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 className="semi-bold">{`${props.value}`}</h2>
      </Box>
    </Box>
  );
}

function convertGpaToPercentage(gpa) {
  let minGpa = 0;
  let maxGpa = 4.0;
  let minPercentage = 0;
  let maxPercentage = 100;

  return (
    ((gpa - minGpa) / (maxGpa - minGpa)) * (maxPercentage - minPercentage) +
    minPercentage
  );
}
