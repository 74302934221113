import React from "react";

function NoPage() {
  return (
    <h1>
      OOPS!
      <br />
      No page found
    </h1>
  );
}

export default NoPage;
