import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

const ProtectedRoute = () => {
  const { auth } = useAuth(); // Use your authentication context
  return auth?.token ? <Outlet /> : <Navigate to="/auth/sign-in" />;
};

export default ProtectedRoute;
