import CBCallendar from "./CBCalendar";
import MonthlyCalendar from "./MonthlyCalendar/MonthlyCalendar";
import "./calendar.scss";
import { useState, useEffect } from "react";
import useAuth from "hooks/useAuth";
import { getEnrolledSessions } from "services/api/getContent";
import { formatDateMonthDayYearTime } from "utils/formatting";

function Calendar() {
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const [enrolledSessions, setEnrolledSessions] = useState([]);
  const [defaultDate, setDefaultDate] = useState(new Date());

  const handleDateChange = (newDate) => {
    setDefaultDate(newDate);
  };

  useEffect(() => {
    (async () => {
      try {
        const fetchedEnrolledSessions = await getEnrolledSessions(
          studentId,
          token
        );
        setEnrolledSessions(fetchedEnrolledSessions.slice(0, 5));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [studentId, token]);

  return (
    <div id="calendar">
      <div className="calendar-view">
        <h2 className="semi-bold-32">Calender</h2>
        <CBCallendar
          enrolledSessions={enrolledSessions}
          defaultDate={defaultDate}
          onDateChange={handleDateChange}
        />
      </div>
      <div className="calendar-tools">
        <div className="calendar-tools__month-view">
          <MonthlyCalendar
            enrolledSessions={enrolledSessions}
            defaultDate={defaultDate}
            onDateChange={handleDateChange}
          />
        </div>
        <div className="calendar-tools__today-schedule">
          <div className="calendar-tools__today-schedule__events-wrapper">
            {enrolledSessions.length === 0 ? (
              <h5>No upcoming sessions</h5>
            ) : (
              <>
                <h5>Top upcoming sessions</h5>
                {enrolledSessions.map((session) => (
                  <div className="calendar-tools__today-schedule__event session">
                    <h6>{session.sessionName}</h6>
                    <span>
                      {formatDateMonthDayYearTime(session.sessionDate)}
                      <br />
                      {session.sessionDuration} mins
                    </span>
                    <summary>{session.sessionDescription}</summary>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Calendar;
