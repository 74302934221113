import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";
import "./classbuddy-slider.scss";
const ClassBuddySlider = ({ children, settings }) => {
  // const settings = {
  //   accessibility: true,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   dots: false,
  //   nextArrow: (
  //     <IconButton>
  //       <ArrowForwardIosTwoTone />
  //     </IconButton>
  //   ),
  //   responsive: [
  //     {
  //       breakpoint: 1500,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const slider = useRef(null);

  return (
    <Slider className="classBuddySlider" ref={slider} {...settings}>
      {children}
    </Slider>
  );
};

export default ClassBuddySlider;
