import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/home-arrow-right.svg";
import ClassBuddySlider from "components/ClassBuddySlider/ClassBuddySlider";
import MentorCard from "components/MentorsCard/MentorCard";
import { useState, useEffect } from "react";
import settings from "../sliderSettings";
import { getPopularDocumentsTutors } from "services/api/getContent";
import useAuth from "hooks/useAuth";
const TopContributorsDocuments = () => {
  const { auth } = useAuth();
  const { studentId, token } = auth;

  const [contributors, setContributors] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedTutors = await getPopularDocumentsTutors(studentId, token);
        setContributors(fetchedTutors);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [studentId, token]);

  return (
    <>
      {contributors.length > 0 ? (
        <section>
          <div className="section-heading">
            <h3>Top documents contributors</h3>
            <Link to="">
              <Button>
                View All
                <ArrowRight />
              </Button>
            </Link>
          </div>
          <ClassBuddySlider settings={settings}>
            {contributors.map((contributor, index) => (
              <MentorCard key={index} mentor={contributor} />
            ))}
          </ClassBuddySlider>
        </section>
      ) : null}
    </>
  );
};

export default TopContributorsDocuments;
