import { axiosPrivate } from "./axios";

// Service to subscribe on tutors
const subscribeOnTutor = async (tutorId, studentId, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Students/${studentId}/subscribe/${tutorId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to subscribe on tutor"
    );
  }
};

// Service to subscribe on tutors
const subscribeOnDocument = async (documentId, studentId, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Students/${studentId}/recommend-documents`,
      [documentId],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to subscribe on document"
    );
  }
};

// Service to subscribe on session
const subscribeOnSession = async (sessionId, studentId, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Students/${studentId}/recommend-sessions`,
      [sessionId],
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to subscribe on session"
    );
  }
};

// Service to enroll in sessions
const enrollInSession = async (sessionId, studentId, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Students/${studentId}/session-enroll/${sessionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to enroll in session"
    );
  }
};

// Service to enroll in documents
const enrollInDocument = async (documentId, studentId, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Students/${studentId}/document-enroll/${documentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to enroll in document"
    );
  }
};

export {
  subscribeOnTutor,
  subscribeOnDocument,
  subscribeOnSession,
  enrollInSession,
};
