import { axiosPrivate } from "./axios";
const voteUp = async (voterId, pollId, token) => {
  try {
    const response = await axiosPrivate.post(
      "/api/Polls/Vote-Up",
      { voterId, pollId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to vote down");
  }
};

const voteDown = async (voterId, pollId, token) => {
  try {
    const response = await axiosPrivate.post(
      "/api/Polls/Vote-Down",
      { voterId, pollId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to vote up");
  }
};

const reviewSession = async (sessionId, studentId, value, review, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Sessions/${sessionId}/rate/${studentId}`,
      { value, review },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    debugger;
    throw new Error(
      error?.response?.data || error.message || "Failed to review the session"
    );
  }
};

const reviewDocument = async (documentId, studentId, value, review, token) => {
  try {
    const response = await axiosPrivate.post(
      `/api/documents/${documentId}/rate/${studentId}`,
      { value, review },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to review the document"
    );
  }
};

export { voteUp, voteDown, reviewSession, reviewDocument };
