import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import AuthContext from "context/AuthProvider";
import payWithCard, {
  transactionFail,
  transactionSuccess,
} from "services/api/payWithCard";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material/";
import "./charge-balance.scss";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const SUCCESS_MESSAGE = "Your transaction has been made successfully";
const ERROR_MESSAGE = "Something went wrong, please try again later";

const ChargeBalance = ({
  openDialog,
  setOpenDialog,
  chargingValue,
  setChargingValue,
  setIsToastOpen,
  setToastMessage,
  setIsSuccess,
}) => {
  const { auth } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();

  const { handleSubmit, reset } = useForm();

  const onSubmit = async () => {
    if (!stripe || !elements) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.log("Card element not found");
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log(error.message);
      return;
    }

    try {
      const paymentData = await payWithCard({
        paymentMethodId: paymentMethod.id,
        amount: chargingValue,
        currency: "EUR", // Adjust according to your needs
        userId: auth.userId,
        token: auth.token,
      });
      console.log("Payment successful 'paymentData':", paymentData);

      const { clientSecret } = paymentData;
      console.log("Client secret received 'clientSecret':", clientSecret);

      if (clientSecret) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: paymentMethod.id,
          }
        );

        if (error) {
          console.log(error.message);
          // Handle errors in payment confirmation
          await transactionFail(paymentMethod.id, auth.token);
          setIsToastOpen(true);
          setIsSuccess(false);
          setToastMessage(ERROR_MESSAGE);
        } else if (paymentIntent) {
          console.log("else if (paymentIntent)", paymentMethod);
          console.log("paymentIntent", paymentIntent);
          console.log("paymentIntent.status", paymentIntent.status);
          if (paymentIntent.status == "succeeded") {
            const success = await transactionSuccess(
              paymentMethod.id,
              auth.token
            );
            console.log("success", success);

            if (success) {
              setIsToastOpen(true);
              setIsSuccess(true);
              setToastMessage(SUCCESS_MESSAGE);
            } else {
              await transactionFail(paymentMethod.id, auth.token);
              setIsToastOpen(true);
              setIsSuccess(false);
              setToastMessage(ERROR_MESSAGE);
            }
          } else {
            setIsToastOpen(true);
            setIsSuccess(false);
            setToastMessage(ERROR_MESSAGE);
          }
        }
      }
      setChargingValue(0);
    } catch (error) {
      setIsToastOpen(true);
      setIsSuccess(false);
      setToastMessage(ERROR_MESSAGE);
      console.error("Payment error:", error.message);
    }

    reset();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setChargingValue(0);
    reset();
  };

  return (
    <Dialog open={openDialog} onClose={handleClose} className="charge-balance">
      <DialogTitle>Charge Balance</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            To charge your balance, please enter your card details below.
          </DialogContentText>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" disabled={!stripe}>
            Charge
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChargeBalance;
