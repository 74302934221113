import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material/";
import "./create-poll.scss";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  TextField,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import pollSchema from "./schema";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useEffect } from "react";
import { createPoll } from "services/api/create";
import useAuth from "hooks/useAuth";

const successMsg = "You've successfully created your poll";
const errorMsg = "Something went wrong";
const CreatePoll = ({ open, handleClose, refreshPollsList }) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(successMsg);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { auth } = useAuth();
  const { userId, token } = auth;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(pollSchema),
  });

  const onSubmit = async (data) => {
    setIsSubmitted(true);
    const { duration, question } = data;
    let sentDuration;
    if (duration === "1 day") sentDuration = 1;
    if (duration === "3 days") sentDuration = 3;
    if (duration === "5 days") sentDuration = 5;
    if (duration === "1 week") sentDuration = 7;

    try {
      const requestBody = {
        userId,
        duration: sentDuration,
        question,
      };
      await createPoll(token, requestBody);
      refreshPollsList();
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      setIsCreated(true);

      reset({
        question: "",
        duration: "",
      });

      handleClose();
    } catch (err) {
      setToastMessage(errorMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      setIsCreated(false);

      reset({
        question: "",
        duration: "",
      });

      if (err.response.data) {
        setToastMessage(err.response.data);
      } else {
        setToastMessage(err.message);
      }
    }
  };

  const handleToastClose = (event, reason) => {
    reset({
      question: "",
      duration: "",
    });
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  return (
    <>
      <Dialog
        className="create-poll"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{"Create poll"}</DialogTitle>
          <DialogContent>
            <Controller
              name="question"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  name="question"
                  fullWidth
                  {...field}
                  label="Suggest a new topic"
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 400 }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Controller
              name="duration"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  fullWidth
                  id="duration"
                  className="create-session__field"
                  options={["1 day", "3 days", "5 days", "1 week"]}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Duration"
                      name="duration"
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              type="submit"
              loading={isSubmitted}
              loadingPosition="end"
            >
              Publish
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity={isCreated ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreatePoll;
