import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "services/api/axios";
import { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const Verify = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);

  useEffect(() => {
    let isComponentMounted = true;

    const verifyUser = async () => {
      const token = encodeURIComponent(searchParams.get("token"));
      const userId = searchParams.get("userId");

      const VERIFY_URL = `/api/ClassBuddyUsers/verify/`;
      try {
        await axios.post(VERIFY_URL, JSON.stringify({ userId, token }), {
          headers: { "Content-Type": "application/json" },
        });
        if (isComponentMounted) {
          setTimeout(() => {
            navigate("/auth/sign-in");
          }, 1000);
        }
      } catch (error) {
        if (isComponentMounted) {
          console.error(error);
          setIsToastOpen(true);
          setErrorMessage(
            error.response?.data?.message ||
              "Verification failed. Please try again."
          );
        }
      }
    };

    verifyUser();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsToastOpen(false);
  };

  return (
    <>
      <h1 style={{ textAlign: "center", marginTop: "80px" }}>
        {errorMessage ? errorMessage : "Verifying..."}
      </h1>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Verify;
