import { Autocomplete, TextField } from "@mui/material";
import React from "react";

export function UniversityInfo({ formData, errors, handleChange, setFormData }) {
  const universities= ["Alex","cairo","menofia"]
  return (
    <div className="group">
      <h3 className="medium--18">University Information:</h3>
      <TextField
        name="userId"
        label="Student ID"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        value={formData.userId}
        onChange={handleChange}
      />
      <TextField
        name="universityId"
        label="University"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChange}
        value={formData.universityId}
      />
      <Autocomplete
        disablePortal
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        options={universities}
        renderInput={(params) => <TextField {...params} label="University" />}
        onChange={(event, newValue) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            universityId: newValue,
          }));
        }}
      />
    </div>
  );
}
