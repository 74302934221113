import "./contact-us.scss";
import { ReactComponent as TelIcon } from "assets/contact-us__tel.svg";
import { ReactComponent as MailIcon } from "assets/contact-us__mail.svg";
import { Autocomplete, Button, TextField } from "@mui/material";

const ContactUs = () => (
  <div id="contact-us" className="contact-us">
    <h3>Contact us</h3>
    <div className="contact-us__get-in-touch">
      <h4>Get in touch</h4>
      <p>
        Want to get in touch? We'd love to hear from you. Here's how you can
        reach us.
      </p>
      <div className="contact-us__get-in-touch__contacts">
        <div>
          <TelIcon />
          +123456789
        </div>
        <div>
          <MailIcon />
          support@classbuddy.com
        </div>
      </div>
    </div>
    <div className="contact-us__form">
      <h4>You can also Submit your requirements directly here:</h4>
      <div className="contact-us__form__fields-wrapper">
        <div className="row">
          <TextField
            fullWidth
            className="contact-us__form__field"
            label="First name*"
            name="firstName"
            onChange={(event, newValue) => {}}
          />
          <TextField
            fullWidth
            className="contact-us__form__field"
            label="Last name*"
            name="lastName"
            onChange={(event, newValue) => {}}
          />
        </div>
        <TextField
          fullWidth
          className="contact-us__form__field"
          label="Email address*"
          name="email"
          onChange={(event, newValue) => {}}
        />
        <Autocomplete
          fullWidth
          className="create-session__field"
          value={""}
          options={[]}
          onChange={(event, newValue) => {}}
          renderInput={(params) => (
            <TextField {...params} label="Subject*" name="subject" />
          )}
        />
        <TextField
          fullWidth
          className="contact-us__form__field message"
          label="Message"
          name="message"
          onChange={(event, newValue) => {}}
          multiline
          rows={5}
        />
        <Button>Submit</Button>
      </div>
    </div>
  </div>
);

export default ContactUs;
