import React, { useState } from "react";
import { UniversityInfo } from "./UniversityInfo";
import { Links } from "./Links";
import { BasicInformation } from "./BasicInformation";
import { Button } from "@mui/material";
function ProfileForm({ formData, setFormData }) {
  const STUDENT_ID = "5618e935-c97c-40de-9195-08dbf9c5b296";
  const [validationErrors, setValidationErrors] = useState({});

  const validate = (data) => {
    let errors = {};
    if (!data.firstName.trim()) {
      errors.firstName = "First name is required";
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid email address";
    }
    if (data.phoneNumber && !/[0-9]{10}/.test(data.phoneNumber)) {
      errors.phoneNumber = "Phone number is not valid";
    }
    if (
      data.twitterLink &&
      !/^(https?:\/\/)?(www\.)?twitter\.com\/([A-Za-z0-9_]{1,15})$/.test(
        data.twitterLink
      )
    ) {
      errors.twitterLink = "Link is not valid";
    }
    if (
      data.githubLink &&
      !/^(https?:\/\/)?(www\.)?github\.com\/([A-Za-z0-9_]{1,15})$/.test(
        data.githubLink
      )
    ) {
      errors.githubLink = "Link is not valid";
    }
    if (
      data.facebookLink &&
      !/^(https?:\/\/)?(www\.)?facebook\.com\/([A-Za-z0-9_]{1,15})$/.test(
        data.facebookLink
      )
    ) {
      errors.facebookLink = "Link is not valid";
    }
    if (
      data.linkedInLink &&
      !/^(https?:\/\/)?(www\.)?linkedin\.com\/([A-Za-z0-9_]{1,15})$/.test(
        data.linkedInLink
      )
    ) {
      errors.linkedInLink = "Link is not valid";
    }
    //
    const dob = new Date(data.dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dob.getFullYear();
    if (age < 15 || age > 35) {
      errors.dateOfBirth = "Your age must be between 15 and 35";
    }
    //

    return errors;
  };

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const errors = validate(formData);
      if (Object.keys(errors).length === 0) {
        const response = await fetch(
          `https://localhost:7110/api/Students/${STUDENT_ID}`,
          {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
          }
        );
        if (response.ok) {
          setValidationErrors("");
          console.log("Profile updated successfully");
        } else {
          setValidationErrors(errors);
          window.parent.scrollTo(0, 0);
          console.error("Failed to update profile");
        }
      } else {
        setValidationErrors(errors);
      }
    } catch (error) {
      console.error("Error occurred while updating profile", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <BasicInformation
          formData={formData}
          setFormData={setFormData}
          errors={validationErrors}
          handleChange={handleChange}
        />
        <Links
          formData={formData}
          setFormData={setFormData}
          errors={validationErrors}
          handleChange={handleChange}
        />
        <UniversityInfo
          formData={formData}
          setFormData={setFormData}
          errors={validationErrors}
          handleChange={handleChange}
        />
        <Button className="Button filled" fullWidth={false} type="submit">
          Save
        </Button>
      </form>
    </>
  );
}

export default ProfileForm;
