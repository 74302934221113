import { Link } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material/";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ReactComponent as CommunityIcon } from "assets/side-menu/community.svg";
import { ReactComponent as HomeIcon } from "assets/aside-home-solid.svg";
import { ReactComponent as PollsIcon } from "assets/side-menu/poll.svg";
import { ReactComponent as FavoriteIcon } from "assets/side-menu/favorite.svg";
import { ReactComponent as ProfileIcon } from "assets/side-menu/profile.svg";
import { ReactComponent as MyTeachingIcon } from "assets/side-menu/my-teaching.svg";
import { ReactComponent as MyLearningIcon } from "assets/side-menu/my-learning.svg";
import { ReactComponent as CalendarIcon } from "assets/side-menu/calendar.svg";
import { ReactComponent as CreateIcon } from "assets/side-menu/create.svg";
import { ReactComponent as CreateSessionIcon } from "assets/side-menu/create-session.svg";
import { ReactComponent as CreateDocumentIcon } from "assets/side-menu/create-document.svg";
import { ReactComponent as SettingsIcon } from "assets/side-menu/settings.svg";
import { ReactComponent as ReviewsIcon } from "assets/side-menu/reviews.svg";
import { ReactComponent as UserInfoIcon } from "assets/side-menu/user-info.svg";
import { ReactComponent as PackagingIcon } from "assets/side-menu/packages.svg";
import { ReactComponent as CreditControlIcon } from "assets/side-menu/credit-control.svg";
import { ReactComponent as FAQIcon } from "assets/side-menu/faq.svg";
import { ReactComponent as ContactUsIcon } from "assets/side-menu/contact-us.svg";
import { ReactComponent as TermsAndConditionsIcon } from "assets/side-menu/terms-and-conditions.svg";

import { useState } from "react";
const MenuLinks = ({ isExpanded }) => {
  const [isCommunityOpen, setIsCommunityOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  return (
    <div className="classbuddy-aside__main">
      <NormalLink
        link="/dashboard"
        icon={<HomeIcon />}
        text="Dashboard"
        isExpanded={isExpanded}
      />
      <ParentLink
        open={isCommunityOpen}
        icon={<CommunityIcon />}
        text="Community"
        isExpanded={isExpanded}
        clickHandler={() => {
          setIsCommunityOpen((prevState) => !prevState);
        }}
      />
      <Collapse in={isCommunityOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NestedLink
            link="/dashboard/community/polls"
            icon={<PollsIcon />}
            text="Polls"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/community/favorite"
            icon={<FavoriteIcon />}
            text="Favorite"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/community/profile"
            icon={<ProfileIcon />}
            text="Profile"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/community/my-teaching"
            icon={<MyTeachingIcon />}
            text="My teaching"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/community/my-learning"
            icon={<MyLearningIcon />}
            text="My learning"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/community/calendar"
            icon={<CalendarIcon />}
            text="Calender"
            isExpanded={isExpanded}
          />
        </List>
      </Collapse>
      <ParentLink
        open={isCreateOpen}
        icon={<CreateIcon />}
        text="Create"
        isExpanded={isExpanded}
        clickHandler={() => {
          setIsCreateOpen((prevState) => !prevState);
        }}
      />
      <Collapse in={isCreateOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NestedLink
            link="/dashboard/create/session"
            icon={<CreateSessionIcon />}
            text="Create session"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/create/document"
            icon={<CreateDocumentIcon />}
            text="Create document"
            isExpanded={isExpanded}
          />
        </List>
      </Collapse>
      <ParentLink
        open={isSettingsOpen}
        icon={<SettingsIcon />}
        text="Settings"
        isExpanded={isExpanded}
        clickHandler={() => {
          setIsSettingsOpen((prevState) => !prevState);
        }}
      />
      <Collapse in={isSettingsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <NestedLink
            link="/dashboard/settings/user-info"
            icon={<UserInfoIcon />}
            text="User info"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/settings/reviews"
            icon={<ReviewsIcon />}
            text="Reviews"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/settings/packaging"
            icon={<PackagingIcon />}
            text="Packaging"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/settings/credit-control"
            icon={<CreditControlIcon />}
            text="Credit control"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/settings/faq"
            icon={<FAQIcon />}
            text="FAQ"
            isExpanded={isExpanded}
          />
          <NestedLink
            link="/dashboard/settings/contact-us"
            icon={<ContactUsIcon />}
            text="Contact us"
            isExpanded={isExpanded}
          />
        </List>
      </Collapse>
    </div>
  );
};

export default MenuLinks;

const NormalLink = ({ link, icon, text, isExpanded }) => {
  return (
    <Link to={link}>
      <ListItemButton>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={isExpanded ? text : null} />
      </ListItemButton>
    </Link>
  );
};

const NestedLink = ({ link, icon, text, isExpanded }) => {
  return (
    <Link to={link}>
      <ListItemButton sx={{ ...(isExpanded && { pl: 4 }) }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={isExpanded ? text : null} />
      </ListItemButton>
    </Link>
  );
};

const ParentLink = ({ open, icon, text, isExpanded, clickHandler }) => {
  return (
    <ListItemButton onClick={clickHandler}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={isExpanded ? text : null} />
      {isExpanded ? open ? <ExpandLess /> : <ExpandMore /> : null}
    </ListItemButton>
  );
};
