import SelectCurrency from "components/SelectCurrency/SelectCurrency";
import "./packaging.scss";
import { useState } from "react";
import { ReactComponent as BasicIcon } from "assets/packaging__basic-icon.svg";
import { ReactComponent as ProIcon } from "assets/packaging__pro-icon.svg";
import { ReactComponent as UltimateIcon } from "assets/packaging__ultimate-icon.svg";
import PackageCard from "./PackageCard/PackageCard";
import ChargeBalance from "components/ChargeBalance/ChargeBalance";
import { Snackbar, Alert } from "@mui/material";

const Packaging = () => {
  const [currency, setCurrency] = useState("euro");
  const [openChargeBalance, setOpenChargeBalance] = useState(false);
  const [chargingValue, setChargingValue] = useState(0);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  return (
    <div id="packaging" className="packaging">
      <header>
        <h3>Packaging</h3>
        <div className="header__row">
          <h4>
            Pick the plans that's right for you!
            <p>Increase your prominence on our site</p>
          </h4>
          <SelectCurrency
            labelText={"Choose your currency"}
            defaultCurrency={currency}
            setCurrency={setCurrency}
          />
        </div>
      </header>
      <div className="packaging__packages">
        {packages.map((packageObj) => (
          <PackageCard
            packageObj={packageObj}
            setChargingValue={setChargingValue}
            setOpenChargeBalance={setOpenChargeBalance}
          />
        ))}
        <ChargeBalance
          openDialog={openChargeBalance}
          setOpenDialog={setOpenChargeBalance}
          chargingValue={chargingValue}
          setChargingValue={setChargingValue}
          setIsToastOpen={setIsToastOpen}
          setToastMessage={setToastMessage}
          setIsSuccess={setIsSuccess}
        />
      </div>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={isSuccess ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Packaging;

const packages = [
  {
    packageName: "Basic",
    packageIcon: <BasicIcon />,
    packageDescription:
      "Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.",
    packageCredit: "100 Cr",
    packagePrice: "$150 USD",
    packageBenefits: [
      "All analytics features",
      "Up to 250,000 tracked visits",
      "Normal support",
    ],
    isPopular: false,
  },
  {
    packageName: "Pro",
    packageIcon: <ProIcon />,
    packageDescription:
      "Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.",
    packageCredit: "300 Cr",
    packagePrice: "$250 USD",
    packageBenefits: [
      "All analytics features",
      "Up to 250,000 tracked visits",
      "Normal support",
    ],
    isPopular: true,
  },
  {
    packageName: "Ultimate",
    packageIcon: <UltimateIcon />,
    packageDescription:
      "Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing elit.",
    packageCredit: "500 Cr",
    packagePrice: "$400 USD",
    packageBenefits: [
      "All analytics features",
      "Up to 250,000 tracked visits",
      "Normal support",
    ],
    isPopular: false,
  },
];
