import React, { useState } from "react";
import { Autocomplete, Box, TextField, Button } from "@mui/material/";
import { Link } from "react-router-dom";

const Header = () => {
  const countries = [
    {
      code: "DE",
    },
    {
      code: "US",
    },
  ];

  const [selectedCountry, setSelectedCountry] = useState(countries[0]);

  const handleChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  return (
    <header>
      <h1>
        Class<strong>Buddy</strong>
      </h1>
      <div className="header__control">
        <Link to="/auth/sign-in">
          <Button className="header__control__sign-in">Sign In</Button>
        </Link>
        <Link to="/auth/sign-up">
          <Button className="header__control__get-started">
            Get started today
          </Button>
        </Link>

        <Autocomplete
          id="country-select-demo"
          sx={{ width: 90 }}
          disableClearable
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.code}
          onChange={handleChange}
          value={selectedCountry}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                display: "flex",
                alignItems: "center",
                "& > img": { mr: 2, flexShrink: 0 },
              }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.code}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={"Language"}
              value={selectedCountry.code} // Set the value to the code of the selected country
              onChange={(event) => {
                // Update the selected country based on user input
                const selected = countries.find(
                  (country) => country.code === event.target.value
                );
                setSelectedCountry(selected || selectedCountry);
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </div>
    </header>
  );
};

export default Header;
