import DocumentCard from "components/DocumentsCard/DocumentsCard";
import "./documents-tab.scss";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import { getTaughtDocuments } from "services/api/getContent";

const DocumentsTab = ({ value, userId, tutorId }) => {
  const { auth } = useAuth();
  const { token } = auth;

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (!tutorId) return;

    (async () => {
      try {
        const documents = await getTaughtDocuments(tutorId, token);
        setDocuments(documents);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div className="documents-tab" role="tabpanel" hidden={value !== 4}>
      <h3>Uploaded documents</h3>
      <div className="documents-tab__documents-wrapper">
        {documents.length > 0 ? (
          documents.map((document, index) => (
            <DocumentCard key={index} document={document} />
          ))
        ) : (
          <h3>No documents to display</h3>
        )}
      </div>
    </div>
  );
};

export default DocumentsTab;
