import { Avatar, IconButton } from "@mui/material";
import "./sideNav.scss";
import { useState, useEffect } from "react";
import { ReactComponent as University } from "../../assets/aside-university.svg";
import { ReactComponent as Home } from "../../assets/aside-home-solid.svg";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import MenuLinks from "./MenuLinks";
import { useUserContext } from "context/UserContext";

function SideNav({ setIsSideNavOpen }) {
  const [userLetters, setUserLetters] = useState("MH");
  const [userName, setUserName] = useState("Mostafa Elhussainy");
  const [isExpanded, setIsExpanded] = useState(true);
  const { userState } = useUserContext();

  useEffect(() => {
    setIsSideNavOpen(isExpanded);
  }, [isExpanded]);
  return (
    <aside className={`classbuddy-aside ${isExpanded ? "" : "not-expanded"}`}>
      <div className="classbuddy-aside__container">
        <IconButton
          className="classbuddy-aside__toggle-button"
          onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
        >
          {isExpanded ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        <figure className="classbuddy-aside__user-figure">
          <Avatar>{userLetters}</Avatar>
          {isExpanded ? (
            <figcaption>
              <strong>
                {`${userState.userFirstName} ${userState.userLastName}`}
              </strong>
              <span>
                <University width={20} height={21} />
                Harvard university
              </span>
            </figcaption>
          ) : null}
        </figure>
        <MenuLinks isExpanded={isExpanded} />
      </div>
    </aside>
  );
}

export default SideNav;
