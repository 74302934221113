import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, TextField, Snackbar, Alert } from "@mui/material";
import sessionSchema from "./schema";
import "./create-session.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { getUniversities } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { createSession } from "services/api/create";
import { convertToISODateString } from "utils/formatting";
import { useNavigate } from "react-router-dom";

const durationOptions = [
  { name: "30 mins", val: 30 },
  { name: "45 mins", val: 45 },
  { name: "60 mins", val: 60 },
  { name: "120 mins", val: 120 },
  { name: "150 mins", val: 150 },
  { name: "180 mins", val: 180 },
];
const successMsg =
  "You've successfully added your session, you'll be redirected";

const CreateSession = () => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(successMsg);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { auth } = useAuth();
  const { tutorId, token } = auth;
  const navigate = useNavigate();

  // const [universities, setUniversities] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const fetchedUniversities = await getUniversities(token);
  //       if (Array.isArray(fetchedUniversities)) {
  //         setUniversities(fetchedUniversities);
  //       }
  //       console.log(fetchedUniversities);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, []);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(sessionSchema),
  });

  const onSubmit = async (data) => {
    setIsSubmitted(true);
    const {
      // universityName,
      subjectName,
      sessionName,
      description,
      language,
      duration,
      sessionDateTime,
      price,
    } = data;
    try {
      const requestBody = {
        tutorId,
        // universityId: universityName,
        sessionName,
        sessionDescription: description,
        sessionDuration: duration,
        sessionDate: convertToISODateString(sessionDateTime),
        capacity: 10,
        price,
        currency: "EUR",
        subjectName,
        language,
      };
      const createResponse = await createSession(token, tutorId, requestBody);
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      setIsCreated(true);
      const { sessionId } = createResponse.data;
      setTimeout(() => {
        navigate(`/dashboard/content/session?sessionId=${sessionId}`);
      }, [4000]);
    } catch (err) {
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      setIsCreated(false);
      if (err.response.data) {
        setToastMessage(err.response.data);
      } else {
        setToastMessage(err.message);
      }
    }
  };

  return (
    <form className="create-session form" onSubmit={handleSubmit(onSubmit)}>
      <h2>Add new session</h2>
      {/* <Controller
        name="universityName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            options={universities} // Assuming "universities" is your array of objects
            getOptionLabel={(option) => option.name} // Display the university's name
            onChange={(_, option) => field.onChange(option ? option.id : "")}
            // You can also use field.onChange(option?.id || '') for a more concise approach
            renderInput={(params) => (
              <TextField
                {...params}
                label="University"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            // Ensure the selected option is correctly determined
            isOptionEqualToValue={(option, value) => option.id === value}
          />
        )}
      /> */}
      <Controller
        name="subjectName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Subject Name"
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="sessionName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Session Name"
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Description"
            multiline
            rows={4}
            inputProps={{ maxLength: 400 }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <Controller
        name="language"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            id="language"
            className="create-session__field"
            options={["EN", "GR"]}
            onChange={(_, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Language"
                name="language"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        )}
      />
      <Controller
        name="duration"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(_, newValue) =>
              field.onChange(newValue ? newValue.val : "")
            }
            options={durationOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Duration"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            isOptionEqualToValue={(option, value) => option.val === value}
            value={
              field.value
                ? // Find the option object that matches the field value using durationOptions
                  durationOptions.find(
                    (option) => option.val === field.value
                  ) || null
                : null
            }
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Price"
            inputProps={{ min: 0 }}
            error={!!error}
            helperText={error ? error.message : null}
            onChange={(e) => {
              if (!Number.isNaN(parseFloat(e.target.value))) {
                field.onChange(parseFloat(e.target.value));
              } else {
                field.onChange(e.target.value);
              }
            }}
          />
        )}
      />
      <Controller
        name="sessionDateTime"
        control={control}
        rules={{ required: "Session date and time is required" }} // React Hook Form validation
        render={({ field, fieldState: { error } }) => (
          <label
            htmlFor="sessionDateTime"
            className={`date-time-label ${error && "error"}`}
          >
            Session Date and Time:
            <input
              id="sessionDateTime"
              type="datetime-local"
              {...field}
              // Optional: Convert to Date object if necessary
              // onChange={(e) => field.onChange(new Date(e.target.value))}
            />
            {error && <span>{error.message}</span>}
          </label>
        )}
      />
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={isCreated ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <LoadingButton type="submit" loading={isSubmitted} loadingPosition="end">
        Create
      </LoadingButton>
    </form>
  );
};

export default CreateSession;
