const dateMonthDayYearTimeOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
  hour12: true,
};

const formatDateMonthDayYearTime = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) {
    return dateString;
  }
  return new Intl.DateTimeFormat("en-US", dateMonthDayYearTimeOptions).format(
    date
  );
};

function convertToISODateString(localDateTime) {
  // Parse the local date-time string to a Date object
  const date = new Date(localDateTime);

  // Convert to UTC by adjusting the timezone offset
  const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  // Return the ISO string
  return utcDate.toISOString();
}

const getNameInitials = (name) => {
  name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();
};

export { formatDateMonthDayYearTime, convertToISODateString, getNameInitials };
