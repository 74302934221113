import { IconButton } from "@mui/material";
import { ReactComponent as EmailIcon } from "assets/profile__email-icon.svg";
import { ReactComponent as FaceBookIcon } from "assets/profile__fb-icon.svg";
import { ReactComponent as InstagramIcon } from "assets/profile__insta-icon.svg";
import { ReactComponent as XIcon } from "assets/profile__x-icon.svg.svg";
import { ReactComponent as LinkedInIcon } from "assets/profile__linkedin-icon.svg.svg";
import { ReactComponent as GithubIcon } from "assets/profile__github-icon.svg.svg";
import MentorCard from "components/MentorsCard/MentorCard";
import ProfileSessionCard from "./ProfileSessionCard";
import VideoIntroductionCard from "./VideoIntroductionCard";
import "./overview.scss";
import useAuth from "hooks/useAuth";
import {
  getPopularDocumentsTutors,
  getPopularSessionsTutors,
  getTaughtSessions,
} from "services/api/getContent";
import { useState, useEffect } from "react";

const OverViewTab = ({ value, userData, tutorId, userId, studentId }) => {
  const {
    userSummary = "Default user summary",
    twitterLink,
    linkedInLink,
    facebookLink,
    instagramLink,
    profilePicture,
    githubLink,
    universityEmail,
  } = userData;
  const [upComingSession, setUpComingSession] = useState(null);
  const [similarContributors, setSimilarContributors] = [];

  const { auth } = useAuth();
  const { token } = auth;

  useEffect(() => {
    if (!studentId) return;
    let documentsTutors, sessionsTutors;

    (async () => {
      try {
        documentsTutors = await getPopularDocumentsTutors(studentId, token);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        sessionsTutors = await getPopularSessionsTutors(studentId, token);
      } catch (error) {
        console.error(error);
      }
    })();

    if (documentsTutors && sessionsTutors) {
      setSimilarContributors(
        documentsTutors.length > sessionsTutors.length
          ? documentsTutors
          : sessionsTutors
      );
    }
  }, [userId, token]);

  const VideoIntroductionData = {
    videoThumbnail: null,
    totalTeachingMins: "4,500",
    numberOfSessionsCompleted: "127",
    avgAttendance: "100%",
    discussedTopics: ["HTML", "JS", "CSS", "UI/UX"],
  };

  useEffect(() => {
    if (!tutorId) return;

    (async () => {
      try {
        const sessions = await getTaughtSessions(tutorId, token);
        setUpComingSession(sessions[0]);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  useEffect(() => {
    if (!tutorId) return;

    (async () => {
      try {
        const sessions = await getTaughtSessions(tutorId, token);
        setUpComingSession(sessions[0]);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div className="overview-tab" role="tabpanel" hidden={value !== 0}>
      <div className="overview-tab__main">
        <div>
          <div className="overview-tab__main__info">
            <p>{userSummary}</p>
            <div className="overview-tab__main__info__share">
              {facebookLink?.length === 0 ? (
                <a target="_blank" href={facebookLink}>
                  <IconButton>
                    <FaceBookIcon />
                  </IconButton>
                </a>
              ) : null}
              {instagramLink?.length === 0 ? (
                <a target="_blank" href={instagramLink}>
                  <IconButton>
                    <InstagramIcon />
                  </IconButton>
                </a>
              ) : null}
              {universityEmail?.length === 0 ? (
                <a href={`mailto:${universityEmail}`}>
                  <IconButton>
                    <EmailIcon />
                  </IconButton>
                </a>
              ) : null}
              {twitterLink?.length === 0 ? (
                <a target="_blank" href={twitterLink}>
                  <IconButton>
                    <XIcon />
                  </IconButton>
                </a>
              ) : null}
              {linkedInLink?.length === 0 ? (
                <a target="_blank" href={linkedInLink}>
                  <IconButton>
                    <LinkedInIcon />
                  </IconButton>
                </a>
              ) : null}
              {githubLink?.length === 0 ? (
                <a target="_blank" href={githubLink}>
                  <IconButton>
                    <GithubIcon />
                  </IconButton>
                </a>
              ) : null}
            </div>
          </div>
          <div className="overview-tab__main__session">
            <h3>Upcoming sessions</h3>
            {upComingSession ? (
              <ProfileSessionCard upComingSession={upComingSession} />
            ) : (
              <h3>This user has no upcoming sessions to display</h3>
            )}
          </div>
        </div>
        <VideoIntroductionCard VideoIntroductionData={VideoIntroductionData} />
      </div>
      {similarContributors?.length > 0 ? (
        <div className="overview-tab__main__contributors">
          <h3>Similar contributors profiles</h3>
          <div className="overview-tab__main__contributors__cards-wrapper">
            {similarContributors.slice(0, 2).map((contributor) => (
              <MentorCard mentor={contributor} />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OverViewTab;
