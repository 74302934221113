import "./my-teaching.scss";
import { ReactComponent as Sessions } from "assets/my-teaching-sessions.svg";
import { ReactComponent as Documents } from "assets/my-teaching-documents.svg";
import NoSessions from "assets/my-teaching-no-session.png";
import NoDocuments from "assets/my-teaching-no-documents.png";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material/";
import { useState, useEffect } from "react";
import SessionFWCard from "components/SessionFWCard/SessionFWCard";
import AddIcon from "@mui/icons-material/Add";
import EventsSummary from "components/EventsSummary/EventsSummary";
import DocumentCard from "components/DocumentsCard/DocumentsCard";
import useAuth from "hooks/useAuth";
import { getTaughtSessions, getTaughtDocuments } from "services/api/getContent";
import { useNavigate } from "react-router-dom";

const MyTeaching = () => {
  const [searchValue, setSearchValue] = useState("");
  const [view, setView] = useState("sessions");
  const [filterValue, setFilterValue] = useState("Filter 1");
  const { auth } = useAuth();
  const { tutorId, token } = auth;
  const [documents, setDocuments] = useState([]);
  const [sessions, setSessions] = useState([]);
  const navigate = useNavigate();

  const addNewHandler = () => {
    navigate("/dashboard/create/session");
  };

  useEffect(() => {
    (async () => {
      try {
        const fetchedSessions = await getTaughtSessions(tutorId, token);
        setSessions(fetchedSessions);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedDocuments = await getTaughtDocuments(tutorId, token);
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div id="my-teaching" className="my-teaching">
      <div className="my-teaching__main">
        <div className="my-teaching__main__heading">
          <h3>My Teaching</h3>
          <Button
            className={view === "sessions" ? "active" : ""}
            onClick={() => setView("sessions")}
          >
            <Sessions /> Sessions
          </Button>
          <Button
            className={view === "documents" ? "active" : ""}
            onClick={() => setView("documents")}
          >
            <Documents /> Documents
          </Button>
          <TextField
            className="my-teaching__main__heading__search-field"
            placeholder="Search"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* <div className="my-teaching__main__control">
          <Select
            className="my-teaching__main__control__filter"
            value={filterValue}
            onChange={(event) => setFilterValue(event.target.value)}
          >
            <MenuItem value={"Filter 1"}>Filter 1</MenuItem>
            <MenuItem value={"Filter 2"}>Filter 2</MenuItem>
          </Select>
        </div> */}
        <div className="my-teaching__main__content">
          {view === "sessions" &&
            (sessions.length > 0 ? (
              sessions.map((session, index) => (
                <SessionFWCard key={index} session={session} />
              ))
            ) : (
              <figure className="my-teaching__empty-figure">
                <img src={NoSessions} alt="" />
                <figcaption>No sessions created yet</figcaption>
              </figure>
            ))}
          {view === "documents" &&
            (documents.length > 0 ? (
              documents.map((document, index) => (
                <DocumentCard key={index} document={document} />
              ))
            ) : (
              <figure className="my-teaching__empty-figure">
                <img src={NoDocuments} alt="" />
                <figcaption>No documents added yet</figcaption>
              </figure>
            ))}
        </div>
      </div>
      <div className="my-teaching__side">
        <Button className="my-teaching__add-new-btn" onClick={addNewHandler}>
          <AddIcon /> Add new
        </Button>
        <EventsSummary />
      </div>
    </div>
  );
};

export default MyTeaching;
