import { Tab, Tabs } from "@mui/material/";
import { useState } from "react";
import OverViewTab from "./OverViewTab/OverViewTab";
import DocumentsTab from "./DocumentsTab/DocumentsTab";
import SessionsTab from "./SessionsTab/SessionsTab";
import SessionsReviewTab from "./SessionsReviewTab";
import DocumentsReviewsTab from "./DocumentsReviewsTab";

const TabsWrapper = ({ activeTab, userData, userId, tutorId, studentId }) => {
  const [value, setValue] = useState(activeTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div id="tabsWrapper">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Overview" />
        <Tab label="Sessions Reviews" />
        <Tab label="Documents Reviews" />
        <Tab label="Sessions" />
        <Tab label="Documents" />
      </Tabs>
      <OverViewTab
        userData={userData}
        value={value}
        userId={userId}
        tutorId={tutorId}
        studentId={studentId}
      />
      <SessionsReviewTab
        value={value}
        userId={userId}
        tutorId={tutorId}
        studentId={studentId}
      />
      <DocumentsReviewsTab
        value={value}
        userId={userId}
        tutorId={tutorId}
        studentId={studentId}
      />
      <SessionsTab
        value={value}
        userId={userId}
        tutorId={tutorId}
        studentId={studentId}
      />
      <DocumentsTab
        value={value}
        userId={userId}
        tutorId={tutorId}
        studentId={studentId}
      />
    </div>
  );
};

export default TabsWrapper;
