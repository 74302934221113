import { z } from "zod";

const schema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required" }), // Updated from .nonempty
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters" })
    .min(1, { message: "Password is required" }), // Updated, but you might not need this line since .min(8) already ensures the string is not empty
});

export default schema;
