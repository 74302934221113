import React from "react";
import "./terms-and-conditions.scss";
const TermsAndConditions = () => {
  const handleLinkClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="terms-and-conditions" id="terms-and-conditions">
      <h3>Terms and conditions</h3>
      <h6>Last Modified: January 3, 2024</h6>
      <p>
        Welcome, and thank you for your interest in ClassBuddy . These Terms and
        conditions apply to our website at <a href="www.classbuddy.com"></a>,
        along with our related websites, hosted applications, mobile or other
        downloadable applications (collectively, the “edX Site”), and other
        services provided by us (collectively with the Classbuddy Site, the
        “Service”). These Terms and conditions are a legally binding contract
        between you and edX regarding your use of the Service.
      </p>
      <h5>Table of contents</h5>
      <ul className="terms-and-conditions__table-of-contents">
        <li>
          <a href="#content-1" onClick={(e) => handleLinkClick(e, "content-1")}>
            1.User responsibilities and conduct
          </a>
        </li>
        <li>
          <a href="#content-2" onClick={(e) => handleLinkClick(e, "content-2")}>
            2.Registration and account security
          </a>
        </li>
        <li>
          <a href="#content-3" onClick={(e) => handleLinkClick(e, "content-3")}>
            3.Payment, Refund and cancelation policy{" "}
          </a>
        </li>
        <li>
          <a href="#content-4" onClick={(e) => handleLinkClick(e, "content-4")}>
            4.Disclaimers and limitations of liability{" "}
          </a>
        </li>
      </ul>
      <ul className="terms-and-conditions__content">
        <li id="content-1">
          <h5>1.User responsibilities and conduct</h5>
          <span>
            You can only use Clasbuddy for lawful purposes. You’re responsible
            for all the content that you post on our platform. You should keep
            the reviews, questions, posts, courses and other content you upload
            in line with our Trust & Safety Guidelines and the law, and respect
            the intellectual property rights of others. We can ban your account
            for repeated or major offenses. If you think someone is infringing
            your copyright on our platform, let us know.
          </span>
          <p>
            You may not access or use the Services or create an account for
            unlawful purposes. Your use of the Services and behavior on our
            platform must comply with applicable local or national laws or
            regulations of your country. You are solely responsible for the
            knowledge of and compliance with such laws and regulations that are
            applicable to you. If you are a student, the Services enable you to
            ask questions to the instructors of courses or other content you are
            enrolled in, and to post reviews of content. For certain content,
            the instructor may invite you to submit content as “homework” or
            tests. Don’t post or submit anything that is not yours. If you are
            an instructor, you can submit content for publication on the
            platform and you can also communicate with the students who have
            enrolled in your courses or other content. In both cases, you must
            abide by the law and respect the rights of others: you cannot post
            any course, question, answer, review or other content that violates
            applicable local or national laws or regulations of your country.
            You are solely responsible for any courses, content, and actions you
            post or take via the platform and Services and their consequences.
            Make sure you understand all the copyright restrictions set forth in
            the Instructor Terms before you submit any content for publication
            on Udemy. If we are put on notice that your course or content
            violates the law or the rights of others (for example, if it is
            established that it violates intellectual property or image rights
            of others, or is about an illegal activity), if we discover that
            your content or behavior violates our Trust & Safety Guidelines, or
            if we believe your content or behavior is unlawful, inappropriate,
            or objectionable (for example if you impersonate someone else), we
            may remove your content from our platform. Udemy complies with
            copyright laws. Check out our Intellectual Property Policy for more
            details. Udemy has discretion in enforcing these Terms and our Trust
            & Safety Guidelines. We may restrict or terminate your permission to
            use our platform and Services or ban your account at any time, with
            or without notice, for any or no reason, including for any violation
            of these Terms, if you fail to pay any fees when due, for fraudulent
            chargeback requests, upon the request of law enforcement or
            government agencies, for extended periods of inactivity, for
            unexpected technical issues or problems, if we suspect that you
            engage in fraudulent or illegal activities, or for any other reason
            in our sole discretion. Upon any such termination we may delete your
            account and content, and we may prevent you from further access to
            the platforms and use of our Services. Your content may still be
            available on the platforms even if your account is terminated or
            suspended. You agree that we will have no liability to you or any
            third party for termination of your account, removal of your
            content, or blocking of your access to our platforms and services.
            If a user has published content that infringes your copyright or
            trademark rights, please let us know. Our Instructor Terms require
            our instructors to follow the law and respect the intellectual
            property rights of others. For more details on how to file a
            copyright or trademark infringement claim with us, see our
            Intellectual Property Policy.
          </p>
        </li>
        <li id="content-2">
          <h5>2.Registration and account security</h5>
          <span>
            In order to use the Services, you must first register with
            Classbuddy through our online registration process, by providing all
            required information which shall include but not be limited to your
            full name and email address. An account shall thereafter be opened
            by Classbuddy, in its sole discretion, for you on the Website (“User
            Account”).
          </span>
          <p>
            Each User Account is personal, non-transferable, and may only be
            registered and used by one (1) single individual/entity. You may not
            access any third party’s account or authorize any third party to
            access your User Account and use the Services on your behalf. You
            acknowledge and agree that Classbuddy may, in its sole discretion,
            deny you access to its Website and/or Services in the event it
            detects a breach of these Terms or any other agreements made between
            you Classbuddy. Where a registered user is a legal entity, the
            natural person (you) registering the User Account on behalf of such
            legal entity warrants that you have the legal capacity and power to
            (i) act on behalf of and represent such legal entity, (ii) proceed
            with the registration of the legal entity and creation of its User
            Account on the Website, (iii) accept these Terms on its behalf, and
            (iv) make payment of the Online Course for and on behalf of such
            legal entity. You agree to (i) provide true, accurate, current and
            complete information about yourself as prompted by Classbuddy
            registration process on the Website, and (ii) maintain and promptly
            update the information provided during registration to keep it true,
            accurate, current, and complete at all times. If you provide any
            information to Classbuddy that is untrue, inaccurate, outdated, or
            incomplete, or if we have reasonable grounds to suspect that such
            information is untrue, inaccurate, outdated, or incomplete, we may
            close your User Account on the Website and deny you the ability to
            access and use the Services. You agree to hold Classbuddy harmless
            and bear full responsibility for all damages accrued by Classbuddy
            in the event of any violation of the obligations provided under this
            Section 3.4. You may indicate, during the registration process, to
            Classbuddy your User Account’s username (which shall be your
            registered email address) and your choic
          </p>
        </li>
        <li id="content-3">
          <h5>3.Payment, Refund and cancelation policy </h5>
          <span>
            When you make a payment, you agree to use a valid payment method. If
            you aren’t happy with your content, Classbuddy offers a 30-day
            refund or credit for most content purchases.
          </span>
          <ul>
            <li>
              <h5>3.1 Pricing</h5>
              <p>
                The prices of content on Udemy are determined based on the terms
                of the Instructor Terms and our Promotions Policy. In some
                instances, the price of content offered on the Udemy website may
                not be exactly the same as the price offered on our mobile or TV
                applications, due to mobile platform providers’ pricing systems
                and their policies around implementing sales and promotions. We
                occasionally run promotions and sales for our content, during
                which certain content is available at discounted prices for a
                set period of time. The price applicable to the content will be
                the price at the time you complete your purchase of the content
                (at checkout). Any price offered for particular content may also
                be different when you are logged into your account from the
                price available to users who aren’t registered or logged in,
                because some of our promotions are available only to new users.
                If you are logged into your account, the listed currency you see
                is based on your location when you created your account. If you
                are not logged into your account, the price currency is based on
                the country where you are located. We do not enable users to see
                pricing in other currencies. If you are a student located in a
                country where use and sales tax, goods and services tax, or
                value added tax is applicable to consumer sales, we are
                responsible for collecting and remitting that tax to the proper
                tax authorities. Depending on your location, the price you see
                may include such taxes, or tax may be added at checkout.
              </p>
            </li>
            <li>
              <h5>3.2 Payments</h5>
              <p>
                You agree to pay the fees for content that you purchase, and you
                authorize us to charge your debit or credit card or process
                other means of payment (such as Boleto, SEPA, direct debit, or
                mobile wallet) for those fees. Udemy works with payment service
                providers to offer you the most convenient payment methods in
                your country and to keep your payment information secure. We may
                update your payment methods using information provided by our
                payment service providers. Check out our Privacy Policy for more
                details. When you make a purchase, you agree not to use an
                invalid or unauthorized payment method. If your payment method
                fails and you still get access to the content you are enrolling
                in, you agree to pay us the corresponding fees within 30 days of
                notification from us. We reserve the right to disable access to
                any content for which we have not received adequate payment.
              </p>
            </li>
            <li>
              <h5>3.3 Refunds and Refund Credits</h5>
              <p>
                If the content you purchased is not what you were expecting, you
                can request, within 30 days of your purchase of the content,
                that Udemy apply a refund to your account. This refund option
                does not apply to Subscription Plan purchases, which are covered
                in Section 8.4 (Payments and Billing) below. We reserve the
                right to apply your refund as a refund credit or a refund to
                your original payment method, at our discretion, depending on
                capabilities of our payment service providers, the platform from
                which you purchased your content (website, mobile or TV app),
                and other factors. No refund is due to you if you request it
                after the 30-day guarantee time limit has passed. However, if
                the content you previously purchased is disabled for legal or
                policy reasons, you are entitled to a refund beyond this 30-day
                limit. Udemy also reserves the right to refund students beyond
                the 30-day limit in cases of suspected or confirmed account
                fraud. To request a refund, follow the steps here. As detailed
                in the Instructor Terms, instructors agree that students have
                the right to receive these refunds. If we decide to issue refund
                credits to your account, they will be automatically applied
                towards your next content purchase on our website, but can’t be
                used for purchases in our mobile or TV applications. Refund
                credits may expire if not used within the specified period and
                have no cash value, in each case unless otherwise required by
                applicable law. At our discretion, if we believe you are abusing
                our refund policy, such as if you’ve consumed a significant
                portion of the content that you want to refund or if you’ve
                previously refunded the content, we reserve the right to deny
                your refund, restrict you from other future refunds, ban your
                account, and/or restrict all future use of the Services. If we
                ban your account or disable your access to the content due to
                your violation of these Terms or our Trust & Safety Guidelines,
                you will not be eligible to receive a refund. Additional
                information on our refund policy is available here.
              </p>
            </li>
            <li>
              <h5>3.4 Gift and Promotional Codes</h5>
              <p>
                Udemy or our partners may offer gift and promotional codes to
                students. Certain codes may be redeemed for gift or promotional
                credits applied to your Udemy account, which then may be used to
                purchase eligible content on our platform, subject to the terms
                included with your codes. Other codes may be directly redeemable
                for specific content. Gift and promotional credits can’t be used
                for purchases in our mobile or TV applications. These codes and
                credits, as well as any promotional value linked to them, may
                expire if not used within the period specified in your Udemy
                account. Gift and promotional codes offered by Udemy may not be
                refunded for cash, unless otherwise specified in the terms
                included with your codes or as required by applicable law. Gift
                and promotional codes offered by a partner are subject to that
                partner’s refund policies. If you have multiple saved credit
                amounts, Udemy may determine which of your credits to apply to
                your purchase. Check out our Support Page and any terms included
                with your codes for more details.
              </p>
            </li>
          </ul>
        </li>
        <li id="content-4">
          <h5>4.Disclaimers and limitations of liability </h5>
          <span>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE EDX
            ENTITIES OR OTHER PROVIDERS BE LIABLE TO YOU FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS)
            ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR
            INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT
            ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
            ANY EDX ENTITY OR ANY OTHER PROVIDER HAS BEEN INFORMED OF THE
            POSSIBILITY OF DAMAGE.
          </span>
          <ul className="sub-ul">
            <li>
              WHILE ClassBuddy TAKES PRECAUTIONS AGAINST SECURITY BREACHES, NO
              WEBSITE OR INTERNET TRANSMISSION IS COMPLETELY SECURE, AND AS
              SUCH, KODEKLOUD AND ITS GROUP AFFILIATES (IF ANY), LICENSORS, AND
              VENDORS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
              INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES THAT MAY
              RESULT FROM UNAUTHORIZED ACCESS, HACKING, DATA LOSS, OR OTHER
              BREACHES THAT MAY OCCUR ON THE WEBSITE, E-LEARNING PLATFORM AND/OR
              THROUGH YOUR USE OF THE SERVICES.
            </li>
            <li>
              YOU ACKNOWLEDGE THAT THE WEBSITE AND E-LEARNING PLATFORM IS FOR
              EDUCATIONAL PURPOSES ONLY, AND ANY MATERIAL OR INFORMATION
              CONTAINED IN THE SERVICES ARE FOR GENERAL INFORMATIONAL PURPOSES
              ONLY. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS,
              KODEKLOUD (INCLUDING ITS GROUP AFFILIATES (IF ANY), ITS LICENSORS,
              VENDORS AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AND
              AGENTS) LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
              OF THE FORM OF ACTION, SHALL AT ALL TIMES BE LIMITED TO A MAXIMUM
              OF THE TOTAL AMOUNTS PAID BY YOU TO KODEKLOUD FOR THE SERVICES IN
              THE PREVIOUS THREE (3) MONTHS PRIOR TO THE DATE OF THE CLAIM.
            </li>
            <li>
              SOME COUNTRIES OR STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION
              OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT
              APPLY TO YOU.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
