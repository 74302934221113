import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Import the plugin
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ReactComponent as NextIcon } from "assets/calendar__right-arrow.svg";
import { ReactComponent as PrevIcon } from "assets/calendar__left-arrow.svg";
import { ReactComponent as PushPinIcon } from "assets/calendar__pushpin.svg";
import "./monthly-calendar.scss";
// Extend dayjs with isSameOrBefore plugin
dayjs.extend(isSameOrBefore);

const CalendarDay = ({ day, hasEvent, currentViewMonth, currentViewYear }) => {
  const today = dayjs().startOf("day");
  const isToday = day.format("YYYY-MM-DD") === today.format("YYYY-MM-DD");
  const isOffMonth =
    day.month() !== currentViewMonth || day.year() !== currentViewYear;

  return (
    <div className="monthly-calendar-day">
      <span
        className={`day-number ${hasEvent ? "has-event" : ""} ${
          isToday ? "is-today" : ""
        } ${isOffMonth ? "off-month" : ""}`}
      >
        {day.date()}
      </span>
      {hasEvent && <PushPinIcon className="pushpin-icon" />}
    </div>
  );
};

const MonthlyCalendar = ({ enrolledSessions, defaultDate, onDateChange }) => {
  const today = dayjs();
  const [currentMonth, setCurrentMonth] = useState(dayjs(defaultDate));

  useEffect(() => {
    setCurrentMonth(dayjs(defaultDate));
  }, [defaultDate]);

  const startDay = currentMonth.startOf("month").startOf("week");
  const endDay = currentMonth.endOf("month").endOf("week");

  const sessionDates = enrolledSessions.map((session) =>
    dayjs(session.sessionDate).format("YYYY-MM-DD")
  );

  const daysArray = Array.from(
    { length: endDay.diff(startDay, "day") + 1 },
    (_, index) => startDay.add(index, "day")
  );

  const isBackwardDisabled = currentMonth.isSameOrBefore(today, "month");

  const prevMonth = () => {
    if (isBackwardDisabled) return;
    const newMonth = currentMonth.subtract(1, "month");
    setCurrentMonth(newMonth);
    onDateChange(newMonth.toDate());
  };

  const nextMonth = () => {
    const newMonth = currentMonth.add(1, "month");
    setCurrentMonth(newMonth);
    onDateChange(newMonth.toDate());
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <div className="monthly-calendar-control">
          <IconButton onClick={prevMonth} disabled={isBackwardDisabled}>
            <PrevIcon />
          </IconButton>
          <span>{currentMonth.format("MMMM YYYY")}</span>
          <IconButton onClick={nextMonth}>
            <NextIcon />
          </IconButton>
        </div>
        <Grid container spacing={2}>
          {daysArray.map((day, index) => (
            <Grid item xs={1.7} key={index}>
              <CalendarDay
                day={day}
                currentViewMonth={currentMonth.month()} // Month number
                currentViewYear={currentMonth.year()} // Year
                hasEvent={sessionDates.includes(day.format("YYYY-MM-DD"))}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </LocalizationProvider>
  );
};

export default MonthlyCalendar;
