import { Avatar, CircularProgress, IconButton } from "@mui/material";
import { ReactComponent as DisLike } from "assets/polls-like-icon.svg";
import { ReactComponent as Like } from "assets/polls-dislike-icon.svg";
import { ReactComponent as AgreeOval } from "assets/polls__agreePer.svg";
import { ReactComponent as DisAgreeOval } from "assets/polls__disAgreePer.svg";
import { ReactComponent as Clock } from "assets/polls__clock.svg";
import "./poll-card.scss";
import { useState, useEffect } from "react";
import { formatDateMonthDayYearTime } from "utils/formatting";
import LoadingButton from "@mui/lab/LoadingButton";
import { voteDown, voteUp } from "services/api/actions";
import useAuth from "hooks/useAuth";

const PollCard = ({ pollData }) => {
  const { auth } = useAuth();
  const { userId, token } = auth;

  const {
    pollId,
    // userId,
    universityId,
    userFullName,
    userAvatar = null,
    universityFullName,
    creationDate,
    pollExpiration,
    agree,
    disagree,
    question,
    isVotedUp,
    isVotedDown,
  } = pollData;

  const [isAgree, setIsAgree] = useState(isVotedUp);
  const [isDisAgree, setIsDisAgree] = useState(isVotedDown);
  const [agreeCount, setAgreeCount] = useState(agree);
  const [disagreeCount, setDisagreeCount] = useState(disagree);
  const [agreePercentage, setAgreePercentage] = useState(
    Math.round((agreeCount * 100) / (agreeCount + disagreeCount)) || 0
  );
  const [disagreePercentage, setDisAgreePercentage] = useState(
    Math.round((disagreeCount * 100) / (agreeCount + disagreeCount)) || 0
  );
  const [isVoting, setIsVoting] = useState(false);
  useEffect(() => {
    const total = agreeCount + disagreeCount;
    const newAgreePercentage =
      total > 0 ? Math.round((agreeCount * 100) / total) : 0;
    const newDisagreePercentage =
      total > 0 ? Math.round((disagreeCount * 100) / total) : 0;

    setAgreePercentage(newAgreePercentage);
    setDisAgreePercentage(newDisagreePercentage);
  }, [agreeCount, disagreeCount]);

  const handleActionBtns = async (action) => {
    setIsVoting(true);
    if (action === "agree") {
      try {
        await voteUp(userId, pollId, token);
        if (!isAgree) {
          setAgreeCount((prevCount) => prevCount + 1);
          setIsAgree((prevState) => true);
        } else {
          setAgreeCount((prevCount) => prevCount - 1);
          setIsAgree(false);
        }
        if (isDisAgree) {
          setDisagreeCount((prevCount) => prevCount - 1);
          setIsDisAgree((prevState) => false);
        }
        setIsVoting(false);
      } catch (error) {
        console.error(error);
        setIsVoting(false);
      }
    }
    if (action === "disagree") {
      try {
        await voteDown(userId, pollId, token);
        if (!isDisAgree) {
          setDisagreeCount((prevCount) => prevCount + 1);
          setIsDisAgree((prevState) => true);
        } else {
          setDisagreeCount((prevCount) => prevCount - 1);
          setIsDisAgree(false);
        }
        if (isAgree) {
          setAgreeCount((prevCount) => prevCount - 1);
          setIsAgree((prevState) => false);
        }
        setIsVoting(false);
      } catch (error) {
        console.error(error);
        setIsVoting(false);
      }
    }
  };
  return (
    <div className="poll-card">
      <figure className="poll-card__user">
        {userAvatar ? (
          <Avatar src={userAvatar} />
        ) : (
          <Avatar>
            {userFullName
              .split(" ")
              .map((word) => word[0])
              .join("")
              .toUpperCase()}
          </Avatar>
        )}
        <figcaption>
          <h5>{userFullName}</h5>
          <h6>{universityFullName}</h6>
          <span>{formatDateMonthDayYearTime(creationDate)}</span>
        </figcaption>
      </figure>
      <figure className="poll-card__statistics">
        <i className="poll-card__statistics__circle">
          <CircularProgress variant="determinate" value={disagreePercentage} />
        </i>
        <figcaption>
          <span className="poll-card__statistics__agreePer">
            <AgreeOval />
            {agreePercentage}% Agree
          </span>
          <span className="poll-card__statistics__disAgreePer">
            <DisAgreeOval />
            {disagreePercentage}% disagree
          </span>
        </figcaption>
      </figure>
      <h4 className="poll-card__info">{question}</h4>
      <span className="poll-card__closingDate">
        <Clock /> This poll closes on
        <strong>{formatDateMonthDayYearTime(pollExpiration)}</strong>
      </span>
      <div className="poll-card__actions">
        <LoadingButton
          // disabled={isAgree}
          loading={isVoting}
          loadingPosition="end"
          className={isAgree && "active-agree"}
          onClick={() => {
            handleActionBtns("agree");
          }}
        >
          <Like /> {agreeCount} Agree
        </LoadingButton>
        <LoadingButton
          // disabled={isDisAgree}
          loading={isVoting}
          loadingPosition="end"
          className={isDisAgree && "active-disagree"}
          onClick={() => {
            handleActionBtns("disagree");
          }}
        >
          <DisLike /> {disagreeCount} Disagree
        </LoadingButton>
      </div>
    </div>
  );
};

export default PollCard;
