import React, { useRef } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const amountRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe.js hasn't loaded yet.");
      return;
    }

    const amount = parseInt(amountRef.current.value, 10) * 100; // Convert to cents
    const currency = "eur";

    const card = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log("Payment Method created:", result.paymentMethod.id);

      const response = await fetch(
        // `${process.env.REACT_APP_BACKEND_URL}/api/Payment/pay/balance`,
        `http://classbuddyapi-dev-dev.eba-fa34u9vw.us-east-1.elasticbeanstalk.com/api/Payment/pay/balance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            UserId: "8410d256-cb79-4d4d-a1f5-f0438a243bc5", //TODO: assign loggged in userId
            amount: amount,
            currency: currency,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Payment processing error:", errorData);
      } else {
        const { clientSecret } = await response.json();
        console.log("Client secret received:", clientSecret);

        if (clientSecret) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: result.paymentMethod.id,
            }
          );

          if (error) {
            console.log(error.message);
            // Handle errors in payment confirmation
          } else if (paymentIntent) {
            console.log("Payment successful:", paymentIntent.id);
            // Handle successful payment here
          }
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Amount:
        <input
          type="number"
          id="amount"
          name="amount"
          ref={amountRef}
          placeholder="Enter amount"
        />
      </label>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

export default CheckoutForm;
