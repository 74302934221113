import { Button } from "@mui/material";
import React from "react";
import UsersAvatar from "assets/landing__hero__users-avatar.png";
import UsersRate from "assets/landing__users-rate.png";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="landing__hero">
      <span>Start to success</span>
      <h3>
        <strong>Level Up</strong>your learning
        <br />
        Connect, Share, Inspire!
      </h3>
      <p>
        Our aim is to connect students from the same universities to facilitate
        knowledge exchange in a course oriented way!
      </p>
      <div className="landing__hero__actions">
        <Link to="/auth/sign-in">
          <Button>Get started today</Button>
        </Link>
        <figure className="landing__hero__actions__avatars">
          <img src={UsersAvatar} alt="users images" />
        </figure>
        <figure className="landing__hero__actions__rate">
          <img src={UsersRate} alt="users rate" />
          <figcaption>(10k+ Reviews)</figcaption>
        </figure>
      </div>
    </section>
  );
};

export default Hero;
