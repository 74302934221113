import "./reviews.scss";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, Button, Rating } from "@mui/material/";
import { useEffect, useState } from "react";
import ReviewRaw from "./Components/ReviewRaw";
import ReviewsMetric from "components/ReviewsMetric/ReviewsMetric";
import useAuth from "hooks/useAuth";
import {
  getTutorDocumentsRatingStats,
  getTutorDocumentsReviews,
  getTutorSessionsRatingStats,
  getTutorSessionsReviews,
} from "services/api/getContent";

const Reviews = () => {
  const [view, setView] = useState("sessions");
  const [searchValue, setSearchValue] = useState("");
  const { auth } = useAuth();
  const { token, tutorId } = auth;

  const [documentsReviews, setDocumentsReviews] = useState([]);
  const [sessionsReviews, setSessionsReviews] = useState([]);
  const [sessionsStats, setSessionsStats] = useState({
    averageRating: null,
    totalRatingsCount: 0,
    ratingCountStarsMap: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  });
  const [documentStats, setDocumentStats] = useState({
    averageRating: null,
    totalRatingsCount: 0,
    ratingCountStarsMap: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  });
  const {
    averageRating: averageRatingDocument,
    totalRatingsCount: totalRatingsCountDocument,
    ratingCountStarsMap: ratingCountStarsMapDocument,
  } = documentStats;
  const {
    averageRating: averageRatingSession,
    totalRatingsCount: totalRatingsCountSession,
    ratingCountStarsMap: ratingCountStarsMapSession,
  } = sessionsStats;

  useEffect(() => {
    if (!tutorId) return;
    (async () => {
      try {
        const reviews = await getTutorDocumentsReviews(tutorId, token);
        setDocumentsReviews(reviews);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const stats = await getTutorDocumentsRatingStats(tutorId, token);
        setDocumentStats(stats);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const reviews = await getTutorSessionsReviews(tutorId, token);
        setSessionsReviews(reviews);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const stats = await getTutorSessionsRatingStats(tutorId, token);
        setSessionsStats(stats);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div id="reviews" className="reviews">
      <div className="reviews__main__heading">
        <h3>Reviews</h3>
        <TextField
          className="reviews__main__heading__search-field"
          placeholder="Search"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="reviews__main-control">
        <Button
          onClick={() => setView("sessions")}
          className={view === "sessions" ? "active" : ""}
        >
          Sessions
        </Button>
        <Button
          onClick={() => setView("documents")}
          className={view === "documents" ? "active" : ""}
        >
          Documents
        </Button>
      </div>
      {view === "sessions" ? (
        <ReviewsMetric
          rating={ratingCountStarsMapSession}
          avgRate={averageRatingSession}
          totalRate={totalRatingsCountSession}
        />
      ) : (
        <ReviewsMetric
          rating={ratingCountStarsMapDocument}
          avgRate={averageRatingDocument}
          totalRate={totalRatingsCountDocument}
        />
      )}

      <div className="reviews__container">
        <div className="reviews__container__wrapper">
          {view === "sessions"
            ? sessionsReviews.map((review) => (
                <ReviewRaw type="session" review={review} />
              ))
            : documentsReviews.map((review) => (
                <ReviewRaw type="document" review={review} />
              ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
