import { ReactComponent as WhiteCheck } from "assets/packaging__white-check-icon.svg";
import { ReactComponent as BlueCheck } from "assets/packaging__blue-check-icon.svg";
import { Button } from "@mui/material";
import "./package-card.scss";

const PackageCard = ({
  packageObj,
  setChargingValue,
  setOpenChargeBalance,
}) => {
  const {
    packageName,
    packageIcon,
    packageDescription,
    packageCredit,
    packagePrice,
    packageBenefits,
    isPopular,
  } = packageObj;

  const handleClickOpen = () => {
    setOpenChargeBalance(true);
    setChargingValue(parseInt(packagePrice.replace(/[^\d]/g, "")) * 100);
  };

  return (
    <div className={`packaging__package ${isPopular ? "popular" : ""}`}>
      {isPopular ? <span className="popular-tag">Popular</span> : null}
      <figure>
        <div className="icon-wrapper">{packageIcon}</div>
        <figcaption>{packageName}</figcaption>
      </figure>
      <p className="packaging__package__description">{packageDescription}</p>
      <h4 className="packaging__package__amount">
        <strong>{packageCredit}</strong>/{packagePrice}
      </h4>
      <ul className="packaging__package__benefits">
        What's included
        {packageBenefits.map((benefit) => (
          <li>
            {isPopular ? <BlueCheck /> : <WhiteCheck />}
            {benefit}
          </li>
        ))}
      </ul>
      <Button onClick={handleClickOpen}>Exchange now</Button>
    </div>
  );
};

export default PackageCard;
