import "./session-details.scss";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import { ReactComponent as StarIcon } from "assets/session-details__star.svg";
import { ReactComponent as ReviewIcon } from "assets/session-details__review.svg";
import { ReactComponent as StudentsIcon } from "assets/session-details__students.svg";
import { ReactComponent as SessionsIcon } from "assets/session-details__sessions.svg";
import { ReactComponent as DiagonalArrowIcon } from "assets/details__arrow-diagonal.svg";
import StudentRunningImg from "assets/session-details__student-running.svg";
import SessionPreviewCard from "./SessionPreviewCard";
import { useEffect, useState } from "react";
import {
  getSessionDetails,
  getTaughtSessions,
  getTutorSessionsRatingStats,
  getTutorSessionsReviews,
} from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { useSearchParams } from "react-router-dom";
import { formatDateMonthDayYearTime } from "utils/formatting";
import SessionCard from "components/SessionCard/SessionCard";
import SubmitReview from "components/SubmitReview";

const SessionDetails = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { token } = auth;
  const [session, setSession] = useState({
    sessionId: sessionId,
    tutorId: "",
    tutorFirstName: "",
    tutorLastName: "",
    tutorUserId: "",
    tutorStudentId: "",
    universityId: "",
    sessionName: "",
    sessionSummary: "",
    sessionDescription: "",
    sessionDuration: "",
    sessionDate: "",
    academicYear: "",
    averageRating: "",
    tutorTotalReviews: "",
    tutorAverageRating: "",
    tutorBio: "",
    totalEnrolledStudents: "",
    roomNumber: "",
    capacity: "",
    price: "",
    currency: "",
    zoomMeetingLink: "",
    isSessionSubscribed: false,
  });
  const [tutorSessions, setTutorSessions] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [tutorSessionsRatingStats, setTutorSessionsRatingStats] = useState({
    averageRating: "",
    totalRatingsCount: "",
    ratingCountStarsMap: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  });

  const {
    tutorId,
    tutorFirstName,
    tutorLastName,
    tutorUserId,
    tutorStudentId,
    universityId,
    sessionName,
    sessionSummary,
    sessionDescription,
    sessionDuration,
    sessionDate,
    academicYear,
    averageRating,
    tutorTotalReviews,
    tutorAverageRating,
    tutorBio,
    totalReviews,
    totalEnrolledStudents,
    roomNumber,
    capacity,
    price,
    currency,
    zoomMeetingLink,
    isSessionSubscribed,
  } = session;

  useEffect(() => {
    if (!sessionId || !token) return;

    (async () => {
      try {
        const details = await getSessionDetails(sessionId, token);
        setSession((prev) => ({ ...prev, ...details }));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [sessionId, token]);

  useEffect(() => {
    if (!tutorId || !token) return;

    (async () => {
      try {
        const sessions = await getTaughtSessions(tutorId, token);
        setTutorSessions(sessions);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const reviews = await getTutorSessionsReviews(tutorId, token);
        setReviews(reviews);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const stats = await getTutorSessionsRatingStats(tutorId, token);
        setTutorSessionsRatingStats(stats);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div className="session-details">
      <SessionPreviewCard
        sessionDate={sessionDate}
        sessionDuration={sessionDuration}
        zoomMeetingLink={zoomMeetingLink}
        price={price}
        sessionId={sessionId}
        isSessionSubscribed={isSessionSubscribed}
      />
      <div className="session-details__hero">
        <span className="session-date">
          session date · {formatDateMonthDayYearTime(sessionDate)}
        </span>
        <h3 className="session-title">{sessionName}</h3>
        <p className="session-summary">{sessionSummary}</p>
        <span className="session-tutor">
          Created by
          <Link
            to={`/dashboard/community/profile?userId=${tutorUserId}&tutorId=${tutorId}&studentId=${tutorStudentId}`}
          >
            {tutorFirstName + " " + tutorLastName}
          </Link>
        </span>
      </div>
      <div className="session-details__overview">
        <h4>session overview</h4>
        <h5 className="session-title">{sessionName}</h5>
        <p className="session-description">{sessionDescription}</p>
      </div>
      {/* <div className="session-details__topics">
        <h4>What topics your will cover</h4>
        <ul className="topics">
          <h5 className="session-title">
            Harness the Potential of AI in Product Management
          </h5>
          <li>Current developments in online teaching</li>
          <li>Current developments in online teaching</li>
          <li>Current developments in online teaching</li>
          <li>Current developments in online teaching</li>
          <li>Current developments in online teaching</li>
          <li>Current developments in online teaching</li>
        </ul>
      </div> */}
      <div className="session-details__tutor">
        <h4>tutor</h4>
        <h5>Meet your instructor</h5>
        <figure>
          <Avatar>
            {tutorFirstName.slice(0, 1) + tutorLastName.slice(0, 1)}
          </Avatar>
          <figcaption>
            <span>
              <StarIcon /> {tutorSessionsRatingStats.averageRating} Tutor Rating
            </span>
            <span>
              <ReviewIcon /> {tutorSessionsRatingStats.totalRatingsCount}{" "}
              Reviews
            </span>
            {/* <span>
              <StudentsIcon /> {totalEnrolledStudents} Students
            </span>
            <span>
              <SessionsIcon /> 100 Sessions
            </span> */}
          </figcaption>
        </figure>
        <h6 className="tutor-title">{tutorFirstName + " " + tutorLastName}</h6>
        <p className="tutor-description">{tutorBio}</p>
        <Link
          className="tutor-profile-link"
          to={`/dashboard/community/profile?userId=${tutorUserId}&tutorId=${tutorId}&studentId=${tutorStudentId}`}
        >
          <Button>
            view tutor profile <DiagonalArrowIcon />
          </Button>
        </Link>
        <SubmitReview
          reviews={reviews}
          type="session"
          contentId={sessionId}
          tutorId={tutorId}
        />
        {tutorSessions?.length > 0 ? (
          <div className="session-details__tutor-more-content">
            <div className="header">
              <h5>
                More content by{" "}
                <strong>{tutorFirstName + " " + tutorLastName}</strong>
              </h5>
              <Button>View all</Button>
            </div>
            <div className="cards-row">
              {tutorSessions?.slice(0, 2).map((session, index) => (
                <SessionCard key={index} session={session} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="session-details__right-content">
        <figure>
          <img src={StudentRunningImg} />
          <figcaption>
            <h5>Find the right content for you</h5>
            <p>
              See your personalized recommendations based on your interests and
              goals.
            </p>
            <Button>Explore more</Button>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default SessionDetails;
