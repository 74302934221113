import React from "react";
import CheckoutForm from "./CheckoutForm";

const MyCheckoutComponent = () => (
  <div>
    <h2>Charge your Balance</h2>
    <CheckoutForm />
  </div>
);

export default MyCheckoutComponent;
