import "./home.scss";
import Hero from "./Hero/Hero";
import MyTeachingDocuments from "./MyTeachingDocuments";
import MyTeachingSessions from "./MyTeachingSessions";
import TopDocuments from "./TopDocuments";
import GetMore from "./GetMore/GetMore";
import RecommendedSessions from "./RecommendedSessions/RecommendedSessions";
import RecommendedDocuments from "./RecommendedDocuments/RecommendedDocuments";
import TopContributorsSessions from "./TopContributorsSessions/TopContributorsSessions";
import TopContributorsDocuments from "./TopContributorsDocuments/TopContributorsDocuments";
import TopSessions from "./TopSessions";
import MyLearningDocuments from "./MyLearningDocuments";
import MyLearningSessions from "./MyLearningSessions";

const Home = () => {
  return (
    <>
      <Hero />
      <MyTeachingDocuments />
      <MyTeachingSessions />
      <MyLearningDocuments />
      <MyLearningSessions />
      <TopDocuments />
      <TopSessions />
      <RecommendedSessions />
      <RecommendedDocuments />
      <TopContributorsSessions />
      <TopContributorsDocuments />
      <GetMore />
    </>
  );
};
export default Home;
