import { z } from "zod";

const schema = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required" }), // Updated from .nonempty
});

export default schema;
