import { LinearProgress } from "@mui/material";
import React,{useState} from "react";

function ProfileCompletion({ values = 0 }) {

  // const handleBlur = (e) => {
  //   const { name } = e.target;
  //   setTouched((prevTouched) => ({
  //     ...prevTouched,
  //     [name]: true,
  //   }));
  // };

  // const calculateCompletionPercentage = () => {
  //   const filledFields = Object.keys(values).filter((field) => touched[field] && values[field] !== "").length;
  //   const percentage = (filledFields / totalFields) * 100;
  //   return Math.round(percentage);
  // };

 
  return (
    <div className="profile-completion">
      <span className="medium--24">Profile Completion</span>
      <LinearProgress variant="determinate" value={values} />
      <span className="medium--18">{values}%</span>
    </div>
  );
}

export default ProfileCompletion;
