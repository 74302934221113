import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./cb-accordion.scss";
const CBAccordion = ({ summary, details }) => {
  return (
    <Accordion className="cb-accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{details}</AccordionDetails>
    </Accordion>
  );
};

export default CBAccordion;
