import "./reviews-metric.scss";
import { ReactComponent as Star } from "assets/star.svg";
import { Rating } from "@mui/material/";

const ReviewsMetric = ({ rating, avgRate, totalRate }) => {
  const { 1: rate1, 2: rate2, 3: rate3, 4: rate4, 5: rate5 } = rating;
  const TOTAL_RATE = rate1 + rate2 + rate3 + rate4 + rate5;
  const calculateWidth = (val) => (val * 400) / TOTAL_RATE + "px";

  return (
    <div className="reviews-metric">
      <h4>
        <Star /> {avgRate || 0} average rating of ({totalRate}) reviews
      </h4>
      <span className="rate rate-5">
        <i
          className="rate-percentage"
          style={{ width: calculateWidth(rate5) }}
        ></i>
        <Rating defaultValue={5} readOnly />({rate5})
      </span>
      <span className="rate rate-4">
        <i
          className="rate-percentage"
          style={{ width: calculateWidth(rate4) }}
        ></i>
        <Rating defaultValue={4} readOnly />({rate4})
      </span>
      <span className="rate rate-3">
        <i
          className="rate-percentage"
          style={{ width: calculateWidth(rate3) }}
        ></i>
        <Rating defaultValue={3} readOnly />({rate3})
      </span>
      <span className="rate rate-2">
        <i
          className="rate-percentage"
          style={{ width: calculateWidth(rate2) }}
        ></i>
        <Rating defaultValue={2} readOnly />({rate2})
      </span>
      <span className="rate rate-1">
        <i
          className="rate-percentage"
          style={{ width: calculateWidth(rate1) }}
        ></i>
        <Rating defaultValue={1} readOnly />({rate1})
      </span>
    </div>
  );
};

export default ReviewsMetric;
