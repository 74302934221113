import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ReactComponent as ArrowUp } from "assets/credit-control__arrow-upIcon.svg";
import { ReactComponent as ArrowDown } from "assets/credit-control__arrow-downIcon.svg";
import "./history.scss";

const History = ({ rows, columns }) => {
  const modifiedColumns = columns.map((column) => {
    if (column.field === "contentName") {
      return {
        ...column,
        flex: 3,
        headerAlign: "center",
        minWidth: 70,
        align: "center",
      };
    }
    if (column.field === "status") {
      return {
        ...column,
        flex: 3,
        headerAlign: "center",
        minWidth: 70,
        align: "center",
        renderCell: (params) => {
          return <div className={params.row.status}>{params.row.status}</div>;
        },
      };
    }
    if (column.field === "transactionAmount") {
      return {
        ...column,
        flex: 3,
        headerAlign: "center",
        minWidth: 70,
        align: "center",
        renderCell: (params) => {
          console.log(params);
          return (
            <div className={`${params.row.income}`}>
              {params.row.transactionAmount}
            </div>
          );
        },
      };
    }
    return {
      ...column,
      headerAlign: "center",
      minWidth: 70,
      flex: 2,
      align: "center",
      ...(column.field === "income" && {
        renderCell: (params) => (
          <div>{params.row.income ? <ArrowDown /> : <ArrowUp />}</div>
        ),
      }),
    };
  });

  return (
    <div className="credit-control__history box large">
      <h4>History</h4>
      <DataGrid
        rows={rows}
        columns={modifiedColumns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        sx={{
          ".MuiDataGrid-columnHeaders": {
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "700",
          },
          ".MuiDataGrid-cell": {
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            border: "none",
          },
          ".MuiDataGrid-root": {
            border: "none",
          },
          // "& .MuiDataGrid-cell": {
          //   justifyContent: "center",
          //   display: "flex",
          //   alignItems: "center",
          // },
        }}
      />
    </div>
  );
};

export default History;
