import ReviewFeedback from "components/ReviewFeedback/ReviewFeedback";
import ReviewsMetric from "components/ReviewsMetric/ReviewsMetric";
import "./reviews-tab.scss";
import { useEffect, useState } from "react";
import {
  getTutorSessionsRatingStats,
  getTutorSessionsReviews,
} from "services/api/getContent";
import useAuth from "hooks/useAuth";

const SessionsReviewTab = ({ value, tutorId }) => {
  const { auth } = useAuth();
  const { token } = auth;

  const [reviews, setReviews] = useState([]);
  const [stats, setStats] = useState({
    averageRating: null,
    totalRatingsCount: 0,
    ratingCountStarsMap: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    },
  });
  const { averageRating, totalRatingsCount, ratingCountStarsMap } = stats;
  useEffect(() => {
    if (!tutorId) return;
    (async () => {
      try {
        const reviews = await getTutorSessionsReviews(tutorId, token);
        setReviews(reviews);
      } catch (error) {
        console.error(error);
      }
    })();
    (async () => {
      try {
        const stats = await getTutorSessionsRatingStats(tutorId, token);
        setStats(stats);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div className="reviews-tab" role="tabpanel" hidden={value !== 1}>
      <ReviewsMetric
        rating={ratingCountStarsMap}
        avgRate={averageRating}
        totalRate={totalRatingsCount}
      />

      {reviews.length > 0 ? (
        reviews.map((userReview) => {
          const {
            userFirstName,
            userLastName,
            universityName,
            reviewerImg = "",
            averageRating,
            reviewDate = "",
            review,
          } = userReview;
          const fullName = userFirstName + " " + userLastName;
          return (
            <ReviewFeedback
              reviewerImg={reviewerImg}
              reviewerName={fullName}
              reviewerUniv={universityName}
              reviewerRate={averageRating}
              reviewDate={reviewDate}
              reviewContent={review}
            />
          );
        })
      ) : (
        <h3>No sessions reviews to display</h3>
      )}
    </div>
  );
};

export default SessionsReviewTab;
