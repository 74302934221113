import { axiosPrivate } from "./axios"; // Adjust the path as necessary

/**
 * Initiates a payment process on the backend using a Stripe payment method ID.
 *
 * @param {Object} params - Parameters for the payment request.
 * @param {string} params.paymentMethodId - The Stripe payment method ID.
 * @param {number} params.amount - The amount to be charged.
 * @param {string} params.currency - The currency of the amount to be charged.
 * @param {string} params.userId - The user ID making the payment.
 * @param {string} params.token - The authentication token for the API request.
 *
 * @returns {Promise<Object>} The response data from the payment process.
 */
const payWithCard = async ({
  paymentMethodId,
  amount,
  currency,
  userId,
  token,
}) => {
  try {
    const response = await axiosPrivate.post(
      "/api/Payment/pay/balance",
      {
        paymentMethodId,
        amount,
        currency,
        UserId: userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // Assuming the response includes the necessary data or confirmation
    console.log("payment success data:", response.data);
    return response.data;
  } catch (error) {
    // Error handling: adapt this as necessary based on your error structure
    throw new Error(error.response?.data?.message || "Payment failed");
  }
};

const transactionSuccess = async (ExternalTransactionId, token) => {
  try {
    const response = await axiosPrivate.post(
      "/api/Payment/send-email/transaction-success",
      { ExternalTransactionId },
      {
        params: {
          ExternalTransactionId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Payment failed");
  }
};

const transactionFail = async (ExternalTransactionId, token) => {
  try {
    const response = await axiosPrivate.post(
      "/api/Payment/send-email/transaction-success",
      { ExternalTransactionId },
      {
        params: {
          ExternalTransactionId,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Payment failed");
  }
};

export default payWithCard;

export { transactionSuccess, transactionFail };
