import { z } from "zod";

const documentSchema = z.object({
  // universityName: z.string().min(1, { message: "University name is required" }),
  subjectName: z.string().min(1, { message: "Subject name is required" }),
  documentName: z.string().min(1, { message: "document name is required" }),
  description: z
    .string()
    .min(1, { message: "document name is required" })
    .max(400, { message: "Description must be under 400 characters" }),
  language: z.string().min(1, { message: "Language is required" }),
  price: z
    .number({ invalid_type_error: "Price must be a number" })
    .nonnegative({ message: "Price cannot be negative" })
    .min(1, { message: "Price is required" }),
  documentFile: z
    .object({
      name: z.string(),
      type: z.enum([
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ]),
      size: z.number().max(50 * 1024 * 1024), // Max 50 MB
    })
    .nullable()
    // .optional()
    .refine((file) => file !== null, {
      message: "A document file is required",
    }),
});

export default documentSchema;
