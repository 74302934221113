import { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "services/api/axios";
import { zodResolver } from "@hookform/resolvers/zod";
import schema from "./schema";
import { TextField, Snackbar, Alert } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./sign-in.scss";
import { useNavigate } from "react-router-dom";
import AuthContext from "context/AuthProvider";
import { Link } from "react-router-dom";

const SIGN_IN_URL = "/api/ClassBuddyUsers/login";
const successMsg = "You've successfully logged in, you'll be redirected";

const SignIn = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(successMsg);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    const { email, password } = data;
    setIsSubmitted(true);
    try {
      const response = await axios.post(
        SIGN_IN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setIsLoggedIn(true);
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      const { userId, studentId, tutorId, refreshToken, token } = response.data;
      setAuth({ userId, studentId, tutorId, refreshToken, token });

      setTimeout(() => {
        navigate("/dashboard/");
      }, [1000]);
    } catch (err) {
      setIsSubmitted(false);
      setIsLoggedIn(false);
      if (err.response.data) {
        setToastMessage(err.response.data);
      } else {
        setToastMessage(err.message);
      }
      setIsToastOpen(true);
    }
  };

  return (
    <div className="sign-in">
      <h3>Sign in</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email Address"
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />
          )}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="submit-btn"
          loading={isSubmitted}
          loadingPosition="end"
        >
          Sign In
        </LoadingButton>
        <p>
          Or you don't have an account? <Link to="/auth/sign-up">sign up</Link>
        </p>
      </form>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={isLoggedIn ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignIn;
