import { z } from "zod";

const sessionSchema = z.object({
  // universityName: z.string().min(1, { message: "University name is required" }),
  // programsNames: z
  //   .string()
  //   .min(1, { message: "At least one program is required" }),
  subjectName: z.string().min(1, { message: "Subject name is required" }),
  sessionName: z.string().min(1, { message: "Session name is required" }),
  description: z
    .string()
    .min(1, { message: "Session name is required" })
    .max(400, { message: "Description must be under 400 characters" }),
  language: z.string().min(1, { message: "Language is required" }),
  duration: z.number().min(1, { message: "Duration is required" }),
  sessionDateTime: z.string().min(1, "Session date and time is required"), // Basic validation as a string
  price: z
    .number({ invalid_type_error: "Price must be a number" })
    .nonnegative({ message: "Price cannot be negative" })
    .min(1, { message: "Price is required" }),
});

export default sessionSchema;
