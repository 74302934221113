import "./polls.scss";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as BreadCrumbIcon } from "assets/polls-breadcrumb-icon.svg";
import { ReactComponent as PollsIcon } from "assets/polls__pollsbtn.svg";
import {
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  Button,
} from "@mui/material/";
import { useState, useEffect } from "react";
import PollCard from "components/PollCard/PollCard";
import CreatePoll from "./CreatePoll";
import { getRecentPolls, getTrendingPolls } from "services/api/getContent";
import useAuth from "hooks/useAuth";

const Polls = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("Trending polls");
  const [open, setOpen] = useState(false);
  const [polls, setPolls] = useState([]);
  const [refreshPolls, setRefreshPolls] = useState(false);
  const { auth } = useAuth();
  const { userId, token } = auth;

  const refreshPollsList = () => {
    setRefreshPolls((prev) => !prev);
  };

  useEffect(() => {
    if (filterValue === "Trending polls") {
      (async () => {
        try {
          const fetchedPolls = await getTrendingPolls(userId, token);
          setPolls(fetchedPolls);
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      (async () => {
        try {
          const fetchedPolls = await getRecentPolls(userId, token);
          setPolls(fetchedPolls);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [userId, token, filterValue, refreshPolls]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="polls" className="polls">
      <CreatePoll
        open={open}
        handleClose={handleClose}
        refreshPollsList={refreshPollsList}
      />
      <div className="polls__heading">
        <div className="polls__heading__main">
          <div className="polls__heading__main__text">
            <h3 className="polls__heading__main__title">
              Current academic polls
            </h3>
            <blockquote className="polls__heading__main__quote">
              "Vote on the topics you need help with and see what other students
              are struggling with too."
            </blockquote>
          </div>
          <Button onClick={handleClickOpen}>
            <PollsIcon />
            Create poll
          </Button>
        </div>
      </div>
      <div className="polls__control">
        <TextField
          className="polls__control__search-field"
          placeholder="Search"
          value={searchValue}
          onChange={(event) => setSearchValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Select
          className="polls__control__filter"
          value={filterValue}
          onChange={(event) => setFilterValue(event.target.value)}
        >
          <MenuItem value={"Trending polls"}>Trending polls</MenuItem>
          <MenuItem value={"Recent polls"}>Recent polls</MenuItem>
        </Select>
      </div>
      <div className="polls__content">
        <h3>{filterValue}</h3>
        <div className="polls__content__poll-cards-wrapper">
          {polls.length > 0 ? (
            polls?.map((poll, index) => (
              <PollCard key={index} pollData={poll} />
            ))
          ) : (
            <h3>There're no polls at the moment</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default Polls;
