import { Button } from "@mui/material";
import "./recommended-documents.scss";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/home-arrow-right.svg";
import ClassBuddySlider from "components/ClassBuddySlider/ClassBuddySlider";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import DocumentCard from "components/DocumentsCard/DocumentsCard";
import { getRecommendedDocuments } from "services/api/getContent";
import settings from "../sliderSettings";

const RecommendedDocuments = () => {
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const fetchedDocuments = getRecommendedDocuments(studentId, token);
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [studentId, token]);
  return (
    <>
      {documents.length > 0 ? (
        <section id="recommended-documents">
          <div className="section-heading">
            <div className="section-heading__text">
              <h3>Recommended documents For You</h3>
              <p>We think you might like these documents.</p>
            </div>
            <Link to="">
              <Button>
                View All
                <ArrowRight />
              </Button>
            </Link>
          </div>
          <ClassBuddySlider settings={settings}>
            {documents.map((document, index) => (
              <DocumentCard key={index} document={document} />
            ))}
          </ClassBuddySlider>
        </section>
      ) : null}
    </>
  );
};

export default RecommendedDocuments;
