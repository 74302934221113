import "./submit-review.scss";
import {
  Button,
  Rating,
  TextField,
  Avatar,
  Snackbar,
  Alert,
} from "@mui/material";
import { getNameInitials } from "utils/formatting";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { reviewSession } from "services/api/actions";
import useAuth from "hooks/useAuth";
import { formatDistanceToNow } from "date-fns";
import { useNavigate } from "react-router-dom";
import { getTutorSessionsReviews } from "services/api/getContent";

const successMsg = "You've successfully submit your review";

const SubmitReview = ({ type, contentId, tutorId }) => {
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const navigate = useNavigate();
  const [contentRate, setContentRate] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(successMsg);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [reviews, setReviews] = useState([]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  const handleSubmitReview = async () => {
    setIsSubmitting(true);
    if (type === "session") {
      try {
        await reviewSession(
          contentId,
          studentId,
          contentRate,
          userComment,
          token
        );
        setContentRate(0);
        setUserComment("");
        setHasReviewed(true);
        setIsToastOpen(true);
        setIsSubmitting(false);
      } catch (error) {
        debugger;
        setContentRate(0);
        setUserComment("");
        setHasReviewed(false);
        setIsToastOpen(true);
        setToastMessage(error.message);
        setIsSubmitting(false);
      }
    }
  };
  useEffect(() => {
    if (!tutorId) return;

    (async () => {
      try {
        const reviews = await getTutorSessionsReviews(tutorId, token);
        setReviews(reviews);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);
  return (
    <div className="submit-review">
      <h4>reviews</h4>
      <div className="submit-review__rate">
        <span>Rate {type === "document" ? "document" : "session"}</span>
        <Rating
          value={contentRate}
          onChange={(event, newValue) => {
            setContentRate(newValue);
          }}
        />
      </div>
      <TextField
        label="Comments"
        value={userComment}
        placeholder="Comment or ask a question"
        onChange={(event) => {
          setUserComment(event.target.value);
        }}
      />
      <LoadingButton
        className="send-review"
        disabled={isSubmitting}
        loading={isSubmitting}
        onClick={handleSubmitReview}
      >
        Send
      </LoadingButton>
      <div className="reviews-column">
        {reviews.length > 0 ? (
          reviews
            .slice(0, 3)
            .map((review) => <ReviewsDetailsCard review={review} />)
        ) : (
          <h4>No reviews</h4>
        )}
      </div>
      <Button
        className="show-all-reviews-btn"
        onClick={() =>
          navigate(
            `/dashboard/community/profile?userId=${tutorId}&reviews=true`
          )
        }
      >
        Show all reviews
      </Button>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={hasReviewed ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SubmitReview;

const ReviewsDetailsCard = ({ review }) => {
  const {
    userFirstName,
    userLastName,
    averageRating,
    date = new Date(),
    review: reviewContent,
  } = review;
  const userName = userFirstName + " " + userLastName;
  return (
    <div className="document-details-review">
      <figure>
        <Avatar>{getNameInitials(userName)}</Avatar>
        <figcaption>
          <span>
            <strong>{userName}</strong> {formatDistanceToNow(date)}
          </span>
          <Rating value={averageRating} readOnly />
        </figcaption>
      </figure>
      <p>{reviewContent}</p>
    </div>
  );
};
