import "./session-card.scss";
import DefaultImg from "assets/session-card-default-img.png";
import { ReactComponent as StudentsIcon } from "assets/session-card-students.svg";
import { ReactComponent as Edit } from "assets/session-card-edit.svg";
import { ReactComponent as View } from "assets/session-card-view.svg";
import { ReactComponent as Join } from "assets/card__blue-arrow__right.svg";
import { Button, IconButton } from "@mui/material";
import { formatDateMonthDayYearTime } from "utils/formatting";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useState } from "react";
import { enrollInSession, subscribeOnSession } from "services/api/subscribe";
import useAuth from "hooks/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUserContext } from "context/UserContext";

const SessionCard = ({ session, ownSession = false }) => {
  const {
    sessionId,
    sessionImg,
    sessionDate,
    sessionName,
    subtitle,
    sessionDescription,
    totalEnrolledStudents,
    price,
    zoomMeetingLink,
    isSubscribed = false,
    isEnrolled = false,
  } = session;
  const [isFavorite, setIsFavorite] = useState(isSubscribed);
  const [hasEnrolled, setHasEnrolled] = useState(isEnrolled);
  const [isFavBtnDisabled, setIsFavBtnDisabled] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [sessionLink, setSessionLink] = useState(zoomMeetingLink);
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const { userState, setUserState } = useUserContext();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/dashboard/content/session?sessionId=${sessionId}`);
  };

  const favoriteSession = async () => {
    setIsFavBtnDisabled(true);
    const subscribed = await subscribeOnSession(sessionId, studentId, token);
    if (subscribed) setIsFavBtnDisabled(false);
    if (subscribed.status == 200) {
      setIsFavorite((prev) => !prev);
    }
  };

  const handleEnrollInSession = async () => {
    setIsEnrolling(true);

    try {
      const zoomLink = await enrollInSession(sessionId, studentId, token);
      setSessionLink(zoomLink);
      setHasEnrolled(true);
      setUserState((prevState) => ({
        ...prevState,
        userTotalBalance: userState.userTotalBalance - price,
      }));
      setIsEnrolling(false);
    } catch (error) {
      console.error(error);
      setIsEnrolling(false);
    }
  };

  return (
    <figure className="session-card">
      <img
        className="session-card__img"
        src={sessionImg || DefaultImg}
        alt=""
        srcset=""
      />
      <IconButton
        className="session-card__fav-button"
        disabled={isFavBtnDisabled}
        onClick={favoriteSession}
      >
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
      <figcaption className="session-card__caption">
        <div className="session-card__date">
          <span className="session-card__date__title">Session date</span>
          <span className="session-card__date__time">
            {formatDateMonthDayYearTime(sessionDate)}
          </span>
        </div>
        <div className="session-card__info">
          <h5 className="session-card__title">{sessionName}</h5>
          <h6 className="session-card__subtitle">{subtitle}</h6>
          <p className="session-card__description">{sessionDescription}</p>
        </div>
        <div className="session-card__details">
          <div className="session-card__details__students">
            <StudentsIcon /> Students:{" "}
            <strong>
              {totalEnrolledStudents ? totalEnrolledStudents : "N/A"}
            </strong>
          </div>
          <div className="session-card__details__price">
            Price: <strong>{price}</strong>
          </div>
        </div>
        <div className="session-card__actions">
          {ownSession ? (
            <>
              <Button className="session-card__actions__edit">
                Edit session <Edit />
              </Button>
              <Button className="session-card__actions__view">
                View session <View />
              </Button>
            </>
          ) : (
            <>
              {hasEnrolled ? (
                <a href={sessionLink}>
                  <Button className="session-card__actions__edit">
                    Join session <Join />
                  </Button>
                </a>
              ) : (
                <LoadingButton
                  disabled={userState.userTotalBalance < price}
                  loading={isEnrolling}
                  onClick={handleEnrollInSession}
                  className="session-card__actions__edit"
                >
                  Join session <Join />
                </LoadingButton>
              )}

              <Button
                onClick={handleNavigate}
                className="session-card__actions__view"
              >
                View session <View />
              </Button>
            </>
          )}
        </div>
      </figcaption>
    </figure>
  );
};

export default SessionCard;
