import {
  List,
  Paper,
  Badge,
  Avatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import "./user-info.scss";
import AvatarPH from "assets/AvatarPlaceholder.jpeg";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import ProfileForm from "./ProfileForm/ProfileForm";
import ProfileCompletion from "./ProfileCompletion/ProfileCompletion";
import { useState, createRef, useEffect } from "react";
import { getUserData } from "services/api/user";
import useAuth from "hooks/useAuth";

function UserInfo() {
  const { auth } = useAuth();
  const { userId, token } = auth;
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    citizenship: "",
    dateOfBirth: "",
    gender: "",
    twitterLink: "",
    facebookLink: "",
    linkedInLink: "",
    githubLink: "",
    universityId: "",
    userId: "",
    gpa: "",
    profilePicture: "",
  });

  useEffect(() => {
    (async () => {
      try {
        const userData = await getUserData(userId, token);
        setUserData(userData.slice(0, 5));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userId, token]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    citizenship: "",
    dateofBirth: "",
    gender: "",
    twitterLink: "",
    facebookLink: "",
    linkedInLink: "",
    githubLink: "",
    universityId: "",
    userId: "",
    gpa: "",
    profilePicture: "",
  });

  const handleUploadClick = () => {
    inputRef.current.click();
  };

  const inputRef = createRef();

  return (
    <Paper className="inner-container">
      <div className="controls">
        <div className="controls-container">
          <figure className="profile-img">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="upload-button"
              ref={inputRef}
            />
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton
                  className="profile-img__edit"
                  variant="contained"
                  onClick={handleUploadClick}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <Avatar
                className="profile-img__wrapper"
                src={selectedImage || AvatarPH}
              />
            </Badge>
            <figcaption className="medium--20">
              {formData.firstName} {formData.lastName}
            </figcaption>
          </figure>
          <div className="gpa">
            <span className="medium--24">Overall GPA</span>
            <CircularProgressWithLabel value={formData.gpa || 0} />
          </div>
          <div className="navigation">
            {/* To be restructured to a component */}
            <List>
              <Link to="">
                <ListItemButton className="navigation__button--active">
                  <ListItemText primary="Profile" />
                </ListItemButton>
              </Link>
              {/* <Link to="">
                <ListItemButton>
                  <ListItemText primary="Account Security" />
                </ListItemButton>
              </Link>
              <Link to="">
                <ListItemButton>
                  <ListItemText primary="Subscription" />
                </ListItemButton>
              </Link>
              <Link to="">
                <ListItemButton>
                  <ListItemText primary="Privacy" />
                </ListItemButton>
              </Link>
              <Link to="">
                <ListItemButton>
                  <ListItemText primary="Notifications" />
                </ListItemButton>
              </Link>
              <Link to="">
                <ListItemButton>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </Link>
              <Link to="">
                <ListItemButton>
                  <ListItemText primary="Delete Account" />
                </ListItemButton>
              </Link> */}
            </List>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div className="heading">
          <h2 className="semi-bold-32">Profile</h2>
          <p className="regular--18">Add information about yourself</p>
        </div>
        <div className="row">
          <ProfileForm formData={formData} setFormData={setFormData} />
          <ProfileCompletion values={25} />
        </div>
      </div>
    </Paper>
  );
}

export default UserInfo;
