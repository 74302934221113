import "./credit-control.scss";
import History from "./History/History";
import CurrencyExchanger from "./CurrencyExchanger/CurrencyExchanger";
import TotalBalance from "./TotalBalance/TotalBalance";
import { useEffect, useState } from "react";
import { getUserStats } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { ReactComponent as Star } from "assets/star.svg";

const rows = [
  {
    id: 1,
    income: true,
    type: "Document",
    contentName: "HTML5",
    transactionAmount: "+50 Cr",
    date: "11:34 PM",
    status: "Fullfil",
  },
  {
    id: 3,
    income: false,
    type: "Document",
    contentName: "JS",
    transactionAmount: "-30 Cr",
    date: "11:34 PM",
    status: "Pending",
  },
];

const columns = [
  { field: "income", headerName: "Income" },
  { field: "contentName", headerName: "Content name" },
  { field: "type", headerName: "Content type" },
  {
    field: "transactionAmount",
    headerName: "Transaction amount",
  },
  {
    field: "date",
    headerName: "Date",
  },
  {
    field: "status",
    headerName: "Status",
  },
];

const CreditControl = () => {
  const { auth } = useAuth();
  const { tutorId, token } = auth;

  const [stats, setStats] = useState({
    averageRating: 0,
    totalDocumentCount: 0,
    totalSessionCount: 0,
    userTotalBalance: 0,
  });

  const {
    averageRating,
    totalDocumentCount,
    totalSessionCount,
    userTotalBalance,
  } = stats;

  useEffect(() => {
    (async () => {
      try {
        const fetchedUserStats = await getUserStats(tutorId, token);
        setStats(fetchedUserStats);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div id="credit-control" className="credit-control">
      <h3>Credit Control </h3>
      <ul className="credit-control__credit-overview">
        <li>
          Current balance
          <span>
            <strong>{userTotalBalance}</strong> Euro
          </span>
        </li>
        <li>
          Current rating
          <span>
            <Star />
            <strong>{averageRating || "N/A"}</strong>
          </span>
        </li>
        <li>
          Uploaded documents
          <span>
            <strong>{totalDocumentCount}</strong>
          </span>
        </li>
        <li>
          Created sessions
          <span>
            <strong>{totalSessionCount}</strong>
          </span>
        </li>
      </ul>
      <TotalBalance />
      {/* <CurrencyExchanger /> */}
      <History rows={rows} columns={columns} />
      {/* <div className="credit-control__credit-info box small">
        <ul>
          <h4>Why credit?</h4>
          <li>
            A clearly marked section on the "Credit Recharge" page titled "Why
            Credits?".
          </li>
          <li>
            Concise bullet points or a short paragraph or an accordion
            explaining the benefits.
          </li>
          <li>
            Visually appealing design with icons or graphics to illustrate
            points.
          </li>
          <li>
            Explain how credits can lead to future rewards, discounts, or
            exclusive access.
          </li>
          <li>
            Emphasize the convenience of transaction processes using credits.
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default CreditControl;
