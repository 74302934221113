import { Autocomplete, Box, TextField } from "@mui/material/";
import React from "react";

const SelectCurrency = ({ defaultCurrency, setCurrency, labelText }) => {
  const currencies = [
    {
      code: "EU",
      label: "Euro",
    },
    // {
    //   code: "US",
    //   label: "USD",
    // },
  ];

  const defaultValue = currencies.find(
    (currency) => currency.label.toLowerCase() === defaultCurrency.toLowerCase()
  );

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      options={currencies}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        setCurrency(newValue.label);
      }}
      defaultValue={defaultValue}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={labelText}
          value={defaultCurrency}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default SelectCurrency;
