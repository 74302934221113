import "./favorite.scss";
import { ReactComponent as Sessions } from "assets/my-teaching-sessions.svg";
import { ReactComponent as Documents } from "assets/my-teaching-documents.svg";
import NoSessions from "assets/my-teaching-no-session.png";
import NoDocuments from "assets/my-teaching-no-documents.png";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, Button } from "@mui/material/";
import { useEffect, useState } from "react";
import SessionCard from "components/SessionCard/SessionCard";
import DocumentCard from "components/DocumentsCard/DocumentsCard";
import useAuth from "hooks/useAuth";
import {
  getFavoriteDocuments,
  getFavoriteSessions,
} from "services/api/getContent";

const Favorite = () => {
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const [searchValue, setSearchValue] = useState("");
  const [view, setView] = useState("sessions");
  const [sessions, setSessions] = useState([]);
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const fetchedSessions = await getFavoriteSessions(studentId, token);
        const fetchedDocuments = await getFavoriteDocuments(studentId, token);
        setDocuments(fetchedDocuments);
        setSessions(fetchedSessions);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [studentId, token]);
  return (
    <div id="favorite" className="favorite">
      <div className="favorite__main">
        <div className="favorite__main__heading">
          <h3>My Favorite</h3>
          <Button
            className={view === "sessions" ? "active" : ""}
            onClick={() => setView("sessions")}
          >
            <Sessions /> Sessions
          </Button>
          <Button
            className={view === "documents" ? "active" : ""}
            onClick={() => setView("documents")}
          >
            <Documents /> Documents
          </Button>
          <TextField
            className="favorite__main__heading__search-field"
            placeholder="Search"
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="favorite__main__content">
          {view === "sessions" &&
            (sessions.length > 0 ? (
              sessions.map((session, index) => (
                <SessionCard key={index} session={session} />
              ))
            ) : (
              <figure className="favorite__empty-figure">
                <img src={NoSessions} alt="" />
                <figcaption>No sessions created yet</figcaption>
              </figure>
            ))}

          {view === "documents" &&
            (documents.length > 0 ? (
              documents.map((document, index) => (
                <DocumentCard key={index} document={document} />
              ))
            ) : (
              <figure className="favorite__empty-figure">
                <img src={NoDocuments} alt="" />
                <figcaption>No documents added yet</figcaption>
              </figure>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Favorite;
