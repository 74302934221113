import { IconButton } from "@mui/material";
import { ArrowForwardIosTwoTone } from "@mui/icons-material";
const settings = {
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  nextArrow: (
    <IconButton>
      <ArrowForwardIosTwoTone />
    </IconButton>
  ),
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default settings;
