import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "components/Layout/Layout";
import Home from "pages/Home/Home";
import Calendar from "pages/Calendar/Calender";
import NoPage from "pages/NoPage/NoPage";
import UserInfo from "pages/Settings/UserInfo/UserInfo";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import ResetPassword from "pages/ResetPassword";
import MyTeaching from "pages/MyTeaching/MyTeaching";
import MyLearning from "pages/MyLearning/MyLearning";
import Reviews from "pages/Reviews/Reviews";
import Favorite from "pages/Favorite/Favorite";
import Polls from "pages/Polls";
import Profile from "pages/Profile/Profile";
import Packaging from "pages/Packaging/Packaging";
import CreditControl from "pages/CreditControl/CreditControl";
import FAQ from "pages/FAQ/FAQ";
import ContactUs from "pages/ContactUs";
import CreateSession from "pages/CreateSession/CreateSession";
import CreateDocument from "pages/CreateDocument/CreateDocument";
import TermsAndConditions from "pages/TermsAndConditions";
import Landing from "pages/Landing";
import Verify from "pages/Verify/Verify";
import MyCheckoutComponent from "pages/PaymentTest/MyCheckoutComponent";
import ProtectedRoute from "./ProtectedRoute";
import useAuth from "hooks/useAuth";
import DocumentDetails from "pages/DocumentDetails";
import SessionDetails from "pages/SessionDetails";

const Routing = () => {
  const { auth } = useAuth();

  return (
    <Router>
      <Routes>
        <Route path="test-payment" element={<MyCheckoutComponent />} />
        <Route
          path="/"
          element={
            auth.token ? <Navigate to="/dashboard" replace /> : <Landing />
          }
        />
        <Route path="*" element={<NoPage />} />
        {/* Authentication Routes */}
        <Route path="auth">
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="verify" element={<Verify />} />
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Layout />}>
            <Route index element={<Home />} />
            {/* Community routes */}
            <Route path="community">
              <Route path="polls" element={<Polls />} />
              <Route path="favorite" element={<Favorite />} />
              <Route path="profile" element={<Profile />} />
              <Route path="my-teaching" element={<MyTeaching />} />
              <Route path="my-learning" element={<MyLearning />} />
              <Route path="calendar" element={<Calendar />} />
            </Route>

            {/* Creating Routes */}
            <Route path="content">
              <Route path="session" element={<SessionDetails />} />
              <Route path="document" element={<DocumentDetails />} />
            </Route>

            {/* Creating Routes */}
            <Route path="create">
              <Route path="session" element={<CreateSession />} />
              <Route path="document" element={<CreateDocument />} />
            </Route>

            {/* Settings Routes */}
            <Route path="settings">
              <Route path="user-info" element={<UserInfo />} />
              <Route path="reviews" element={<Reviews />} />
              <Route path="packaging" element={<Packaging />} />
              <Route path="credit-control" element={<CreditControl />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
