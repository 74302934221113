import SectionImg from "assets/landing-page__why-tutor.png";
import { ReactComponent as Tick } from "assets/landing__tick-icon.svg";

const WhyTutor = () => {
  return (
    <section id="why-tutor">
      <img src={SectionImg} alt="books, people and income" />
      <div className="section-details">
        <span>How to earn money in ClassBuddy ?</span>
        <h3>
          Why tutor on <strong>ClassBuddy</strong> ?
        </h3>
        <p>
          here are many reasons to make ClassBuddy your go-to source for online
          tutoring work.
        </p>
        <ul>
          <li>
            <Tick />
            <span>
              <strong>Lowest Service Fees</strong>
              ClassBuddy charges the lowest service fees in the market. We like
              to give our tutor the biggest revenue
            </span>
          </li>
          <li>
            <Tick />
            <span>
              <strong>Reliable Income</strong>
              Make good money using nothing more than your knowledge and your
              skills
            </span>
          </li>
          <li>
            <Tick />
            <span>
              <strong>Full Freedom and Flexibility</strong>
              Choose when to work and where you want to work from. There's no
              boss,. Create your own Schedule!
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default WhyTutor;
