import { ReactComponent as UniversityIcon } from "assets/profile__university-icon.svg";
import { ReactComponent as Dots } from "assets/profile__3dots.svg";
import { Avatar, Button, IconButton, Menu, MenuItem } from "@mui/material";
import TabsWrapper from "./Tabs/TabsWrapper";
import "./profile.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import { getUserData } from "services/api/user";
import { useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { subscribeOnTutor } from "services/api/subscribe";

const Profile = () => {
  const profileUrl = useLocation();
  const [isFollowed, setIsFollowed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userId, setUserId] = useState("");
  const [tutorId, setTutorId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [userMenu, setUserMenu] = useState(null);
  const [userData, setUserData] = useState({
    userImg: null,
    firstName: "",
    lastName: "",
    universityName: "",
  });
  const {
    userImg,
    firstName,
    lastName,
    universityName = "Alexandria university", // to be added in the api
  } = userData;
  const USER_NAME = firstName + " " + lastName;
  const [searchParams] = useSearchParams();
  const TARGET_USER_ID = searchParams.get("userId");
  const TARGET_STUDENT_ID = searchParams.get("studentId");
  const TARGET_TUTOR_ID = searchParams.get("tutorId");
  const IS_SESSIONS_REVIEWS_ACTIVE = searchParams.get("reviews-sessions");
  const IS_DOCUMENTS_REVIEWS_ACTIVE = searchParams.get("reviews-documents");
  const IS_SESSIONS_ACTIVE = searchParams.get("sessions");
  const IS_DOCUMENTS_ACTIVE = searchParams.get("documents");

  const TAB_VALUE = IS_SESSIONS_REVIEWS_ACTIVE
    ? 1
    : IS_DOCUMENTS_REVIEWS_ACTIVE
    ? 2
    : IS_SESSIONS_ACTIVE
    ? 3
    : IS_DOCUMENTS_ACTIVE
    ? 4
    : 0;

  const { auth } = useAuth();
  const {
    token,
    userId: selfUserId,
    tutorId: selfTutorId,
    studentId: selfStudentId,
  } = auth;

  useEffect(() => {
    if (TARGET_USER_ID) {
      setUserId(TARGET_USER_ID);
    } else {
      setUserId(selfUserId);
    }
    if (TARGET_TUTOR_ID) {
      setTutorId(TARGET_TUTOR_ID);
    } else {
      setTutorId(selfTutorId);
    }
    if (TARGET_STUDENT_ID) {
      setStudentId(TARGET_STUDENT_ID);
    } else {
      setStudentId(selfStudentId);
    }
  }, [
    TARGET_TUTOR_ID,
    TARGET_USER_ID,
    TARGET_STUDENT_ID,
    selfUserId,
    selfTutorId,
    selfStudentId,
  ]);

  useEffect(() => {
    if (!userId) return;

    (async () => {
      try {
        const userData = await getUserData(userId, token);
        setUserData(userData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [userId, token]);

  const handleFollowTutor = async () => {
    setIsSubmitting(true);
    try {
      const response = await subscribeOnTutor(tutorId, studentId, token);
      setIsSubmitting(false);
      setIsFollowed((prev) => !prev);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };
  return (
    <div id="profile" className="profile">
      <div className="profile__heading">
        <figure>
          {userImg ? (
            <Avatar src={userImg} />
          ) : (
            <Avatar>
              {USER_NAME.split(" ")
                .map((word) => word[0])
                .join("")
                .toUpperCase()}
            </Avatar>
          )}
          <figcaption>
            <h2>{USER_NAME}</h2>
            <h5>
              <UniversityIcon /> Student at {universityName}
            </h5>
          </figcaption>
        </figure>
        <div className="profile__heading__control">
          {TARGET_USER_ID ? (
            <LoadingButton
              className="profile__heading__control__follow"
              loading={isSubmitting}
              loadingPosition="end"
              onClick={handleFollowTutor}
            >
              {isFollowed ? "Unfollow" : "Follow"}
            </LoadingButton>
          ) : null}

          <IconButton
            className="profile__heading__control__edit"
            onClick={(event) => setUserMenu(event.currentTarget)}
          >
            <Dots />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            anchorEl={userMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(userMenu)}
            onClose={() => setUserMenu(null)}
          >
            <MenuItem
              onClick={() => {
                console.log(profileUrl.pathname);
                navigator.clipboard.writeText(profileUrl.pathname);
              }}
            >
              Copy profile link
            </MenuItem>
            <MenuItem onClick={() => {}}>Report profile</MenuItem>
          </Menu>
        </div>
      </div>
      <TabsWrapper
        activeTab={TAB_VALUE}
        userData={userData}
        userId={userId}
        tutorId={tutorId}
        studentId={studentId}
      />
    </div>
  );
};

export default Profile;
