import { z } from "zod";

const pollSchema = z.object({
  question: z
    .string()
    .min(1, { message: "Topic is required" })
    .max(400, { message: "Topic description must be under 400 characters" }),
  duration: z.string().min(1, { message: "Duration is required" }),
});

export default pollSchema;
