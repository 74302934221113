import { Outlet } from "react-router-dom";
import { useState } from "react";
import NavBar from "../NavBar/NavBar";
import SideNav from "../SideNav/SideNav";
import "./layout.scss";
function Layout() {
  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  return (
    <div className="classbuddy-layout">
      <NavBar />
      <SideNav setIsSideNavOpen={setIsSideNavOpen} />
      <main className={isSideNavOpen ? "expanded" : ""}>
        <div className="container">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default Layout;
