import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/home-arrow-right.svg";
import ClassBuddySlider from "components/ClassBuddySlider/ClassBuddySlider";
import settings from "../sliderSettings";
import { getTaughtDocuments } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { useState, useEffect } from "react";
import DocumentCard from "components/DocumentsCard/DocumentsCard";

const MyTeachingDocuments = () => {
  const { auth } = useAuth();
  const { tutorId, token } = auth;

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedDocuments = await getTaughtDocuments(tutorId, token);
        setDocuments(fetchedDocuments);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <>
      {documents.length > 0 ? (
        <section>
          <div className="section-heading">
            <h3>My teaching documents</h3>
            <Link to="/dashboard/community/my-teaching">
              <Button>
                View All
                <ArrowRight />
              </Button>
            </Link>
          </div>
          <ClassBuddySlider settings={settings}>
            {documents.map((document, index) => (
              <DocumentCard key={index} document={document} />
            ))}
          </ClassBuddySlider>
        </section>
      ) : null}
    </>
  );
};

export default MyTeachingDocuments;
