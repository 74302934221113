import "./cb-search.scss";
import { InputAdornment, TextField } from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";

const CBSearch = ({ searchValue, setSearchValue }) => {
  return (
    <TextField
      className="CB__search-field"
      placeholder="Search"
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CBSearch;
