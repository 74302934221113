import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./pdf-viewer.scss";
import { Button, Tooltip, IconButton } from "@mui/material";
import { ReactComponent as DownloadIcon } from "assets/document-details__download-icon.svg";
import { ReactComponent as GoNextIcon } from "assets/document-details__go-next.svg";
import { ReactComponent as GoNextSkipIcon } from "assets/document-details__go-next-skip.svg";
import { ReactComponent as GoPrevIcon } from "assets/document-details__go-prev.svg";
import { ReactComponent as GoPrevSkipIcon } from "assets/document-details__go-prev-skip.svg";
import { ReactComponent as LockIcon } from "assets/document-details__lock.svg";
import { ReactComponent as CheckIcon } from "assets/document-details__true-icon.svg";

// Setup for pdf.js worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const PDFViewer = ({ file }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const freeTrialPages = 3; // Limit for free trial

  const handlePageInputChange = (e) => {
    setPageNumber(e.target.value); // Update input value as user types
  };

  const handlePageInputBlur = () => {
    let newPageNum = parseInt(pageNumber, 10);
    if (!isNaN(newPageNum)) {
      newPageNum = Math.max(1, Math.min(numPages, newPageNum)); // Ensure new page number is within bounds
      setPageNumber(newPageNum);
    } else {
      setPageNumber(pageNumber); // Reset input value if it's not a valid number
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="pdf-viewer">
      <Button className="download-btn">
        <a href={file} download>
          <DownloadIcon />
          Download
        </a>
      </Button>
      <div className="document-wrapper">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div className="pdf-viewer__control">
          <div className="navigation">
            <Tooltip title="Skip to beginning">
              <IconButton
                onClick={() => setPageNumber(1)}
                disabled={pageNumber === 1}
              >
                <GoPrevSkipIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Go to previous page">
              <IconButton
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                <GoPrevIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Go to next page">
              <IconButton
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber >= numPages || pageNumber > freeTrialPages}
              >
                <GoNextIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Skip to end">
              <IconButton
                onClick={() => setPageNumber(numPages)}
                disabled={freeTrialPages || pageNumber === numPages}
              >
                <GoNextSkipIcon />
              </IconButton>
            </Tooltip>
          </div>
          <span className="pagination">
            <input
              type="number"
              value={pageNumber}
              onChange={handlePageInputChange}
              onBlur={handlePageInputBlur}
              style={{ width: "50px", marginRight: "10px" }}
            />
            <span> out of {numPages}</span>
          </span>
          <Button className="download-btn">
            <a href={file} download>
              <DownloadIcon />
              Download
            </a>
          </Button>
        </div>
        {pageNumber > freeTrialPages && (
          <div className="preview-popup">
            <h5>
              <LockIcon /> This is a preview
            </h5>
            <p>Do you want a full access? purchase and unlock all pages</p>
            <div className="features">
              <span>
                <CheckIcon /> Access to the full document
              </span>
              <span>
                <CheckIcon /> Get unlimited download for the document
              </span>
            </div>
            <Button>Buy document now!</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PDFViewer;
