import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CBAreaChart = ({ data, xAxisName, yAxisName }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart data={data}>
        <CartesianGrid stroke="transparent" />
        <XAxis dataKey={xAxisName} axisLine={false} tickMargin={10} />
        <YAxis dataKey={yAxisName} axisLine={false} tickMargin={10} />
        <Tooltip />
        <defs>
          <linearGradient id="areaColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FA7518" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FA7518" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey={yAxisName}
          stroke="#FA7518"
          fill="url(#areaColor)"
          axisLine={false}
          tickMargin={10}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CBAreaChart;
