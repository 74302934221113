import { TextField } from "@mui/material";
import React from "react";
export function Links({ formData, errors, handleChange }) {
  return (
    <div className="group">
      <h3 className="medium--18">Links:</h3>
      <TextField
        name="twitterLink"
        label="Twitter"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        value={formData.twitterLink}
        onChange={handleChange("twitterLink")}
      />
      {errors.twitterLink && <span>{errors.twitterLink}</span>}

      <TextField
        name="facebookLink"
        label="Facebook"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        value={formData.facebookLink}
        onChange={handleChange("facebookLink")}
      />
      {errors.facebookLink && <span>{errors.facebookLink}</span>}

      <TextField
        name="linkedInLink"
        label="LinkedIn"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        value={formData.linkedInLink}
        onChange={handleChange("linkedInLink")}
      />
      {errors.linkedInLink && <span>{errors.linkedInLink}</span>}

      <TextField
        name="githubLink"
        label="Github"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        value={formData.githubLink}
        onChange={handleChange("githubLink")}
      />
      {errors.githubLink && <span>{errors.githubLink}</span>}
    </div>
  );
}
