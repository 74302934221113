import "./hero.scss";
import { ReactComponent as Star } from "assets/star.svg";
import { useEffect } from "react";
import { getUserStats } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { useUserContext } from "context/UserContext";

function Hero() {
  const { auth } = useAuth();
  const { tutorId, token } = auth;
  const { userState, setUserState } = useUserContext();

  useEffect(() => {
    if (userState.tutorId) return;

    (async () => {
      try {
        const fetchedUserStats = await getUserStats(tutorId, token);
        setUserState(fetchedUserStats);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <section id="hero" className="hero">
      <span className="hero__user-greeting">
        Hello <strong>Mostafa</strong>
      </span>
      <blockquote>
        "You must be the change you wish to see in the world"
      </blockquote>
      <div className="hero__user-info">
        <div>
          Current balance
          <strong>
            <span>{userState.userTotalBalance}</span> Euro
          </strong>
        </div>
        <div>
          Current rating
          <strong>
            <Star />
            <span>{userState.averageRating || "N/A"}</span>
          </strong>
        </div>
        <div>
          Uploaded documents
          <strong>
            <span>{userState.totalDocumentCount}</span>
          </strong>
        </div>
        <div>
          Created sessions
          <strong>
            <span>{userState.totalSessionCount}</span>
          </strong>
        </div>
        {/* <div>
          Monthly profit
          <strong>
            <span>800</span>Cr
          </strong>
        </div> */}
      </div>
    </section>
  );
}

export default Hero;
