import "./review-raw.scss";
import { ReactComponent as ArrowRight } from "assets/home-arrow-right.svg";
import SessionDefaultImg from "assets/review-session-default-img.png";
import { Button } from "@mui/material/";
import { ReactComponent as Star } from "assets/star.svg";
import ReviewFeedback from "components/ReviewFeedback/ReviewFeedback";
import { Link } from "react-router-dom";

const ReviewRaw = ({ type, review }) => {
  let sessionId,
    sessionTitle,
    sessionImg,
    sessionDescription,
    documentId,
    documentName,
    documentImg;

  const {
    userFirstName,
    userLastName,
    universityName,
    userProfilePicture,
    reviewerRate,
    reviewDateTime,
    averageRating,
    rateCounter,
    price,
    review: reviewContent,
  } = review;
  const reviewerName = userFirstName + " " + userLastName;

  if (type === "session") {
    sessionId = review.sessionId;
    sessionTitle = review.sessionName;
    sessionImg = review.sessionImg;
    sessionDescription = review.sessionDescription;
  } else {
    documentId = review.documentId;
    documentName = review.documentName;
    documentImg = review.documentImg;
  }

  return (
    <div className="review-row">
      <ReviewFeedback
        reviewerImg={userProfilePicture}
        reviewerName={reviewerName}
        reviewerUniv={universityName}
        reviewerRate={reviewerRate}
        reviewDate={reviewDateTime}
        reviewContent={reviewContent}
      />
      <div className="review-row__session-card">
        <h6>{type === "session" ? "Session:" : "Document:"}</h6>
        <figure>
          <div className="review-row__session-card__figure-row">
            <img
              className="review-row__session-card__img"
              src={
                (type === "session" ? sessionImg : documentImg) ||
                SessionDefaultImg
              }
            />
            <figcaption>
              <span className="review-row__session-card__title">
                {type === "session" ? sessionTitle : documentName}
                <strong className="review-row__session-card__title__rate">
                  <Star /> {averageRating} ({rateCounter})
                </strong>
              </span>
              <span className="review-row__session-card__description">
                {sessionDescription}
              </span>
            </figcaption>
          </div>
          <div className="review-row__session-card__details">
            <span className="review-row__session-card__details__price">
              {price} Euro
            </span>
            <Link
              to={
                type === "session"
                  ? `/dashboard/content/session?sessionId=${sessionId}`
                  : `/dashboard/content/document?documentId=${documentId}`
              }
            >
              <Button className="review-row__session-card__details__more-btn">
                More details <ArrowRight />
              </Button>
            </Link>
          </div>
        </figure>
      </div>
    </div>
  );
};

export default ReviewRaw;
