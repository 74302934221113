import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useState, useEffect } from "react";
import "./cb-calendar.scss";
import { AccessTime } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

function formatTimeRange(start, end) {
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  return `${start.toLocaleTimeString(
    "en-US",
    options
  )}-${end.toLocaleTimeString("en-US", options)}`;
}

function CBCallendar({ enrolledSessions, defaultDate, onDateChange }) {
  const [currentViewMonth, setCurrentViewMonth] = useState(moment().month());

  const navigate = useNavigate();
  const mappedSessions = enrolledSessions.map((session) => {
    const startDate = new Date(session.sessionDate);
    const endDate = new Date(
      startDate.getTime() + session.sessionDuration * 60000
    );

    return {
      sessionId: session.sessionId,
      sessionName: session.sessionName,
      start: startDate,
      end: endDate,
      date: formatTimeRange(startDate, endDate),
      description: session.sessionDescription,
    };
  });

  const handleSlotSelect = ({ start, end }) => {
    console.log("Selected Time Slot:", start, end);
  };

  const handleEventSelect = (event) => {
    navigate(`/dashboard/content/session?sessionId=${event.sessionId}`);
  };

  const handleNavigate = (date) => {
    onDateChange(date);
  };

  useEffect(() => {
    setCurrentViewMonth(moment(defaultDate).month());
  }, [defaultDate]);

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: isSelected ? "#3174ad" : event.color,
      color: isSelected ? "white" : "black",
    };
    if (event.isHidden) {
      style.display = "none";
    }
    return {
      style: style,
      className: event.isHidden ? "hidden-event" : "",
    };
  };

  const eventContent = ({ event }) => {
    return (
      <div className="event">
        <strong>{event.sessionName}</strong>
        <span>{event.date}</span>
        <p>{event.description}</p>
      </div>
    );
  };

  const dayPropGetter = (date) => {
    const dateStr = moment(date).format("YYYY-MM-DD");
    const viewMonth = currentViewMonth || moment().month(); // Use current view month, default to current month
    const dateMonth = moment(date).month(); // Extract month from the date

    const hasEvents = processedEvents.some(
      (event) => moment(event.start).format("YYYY-MM-DD") === dateStr
    );

    let backgroundColor = "#FFFFFF"; // Default background color for days in the current viewed month
    if (dateMonth !== viewMonth) {
      backgroundColor = "#adadad"; // Set background color to red for days not in the viewed month
    } else if (hasEvents) {
      backgroundColor = "#e3ebfc"; // Set background color for days with events
    }

    return {
      style: {
        backgroundColor: backgroundColor,
      },
    };
  };

  const processEvents = (events) => {
    const eventsByDay = events.reduce((acc, event) => {
      const dayKey = moment(event.start).format("YYYY-MM-DD");
      if (!acc[dayKey]) {
        acc[dayKey] = [];
      }
      acc[dayKey].push(event);
      return acc;
    }, {});

    Object.keys(eventsByDay).forEach((dayKey) => {
      const dayEvents = eventsByDay[dayKey];
      dayEvents.sort((a, b) => a.start - b.start);
      dayEvents.forEach((event, index) => {
        event.isHidden = index >= 2;
      });

      if (dayEvents.length > 2) {
        dayEvents[2] = {
          sessionName: `+${dayEvents.length - 2} more`,
          start: dayEvents[2].start,
          end: dayEvents[2].end,
          isMoreEventIndicator: true,
          isHidden: false,
        };
      }
    });

    return Object.values(eventsByDay).flat();
  };
  const processedEvents = processEvents(mappedSessions);

  return (
    <div className="CBCallendar">
      <Calendar
        date={defaultDate}
        localizer={localizer}
        events={processedEvents}
        startAccessor="start"
        endAccessor="end"
        defaultDate={new Date(defaultDate)}
        onSelectSlot={handleSlotSelect}
        onSelectEvent={handleEventSelect}
        onNavigate={handleNavigate}
        selectable
        eventPropGetter={eventStyleGetter}
        dayPropGetter={dayPropGetter}
        components={{
          event: eventContent,
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}

export default CBCallendar;

// CustomToolbar.js
const CustomToolbar = ({ onNavigate, label }) => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  const labelYear = parseInt(label.split(" ")[1], 10);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const labelMonth = monthNames.indexOf(label.split(" ")[0]);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  useEffect(() => {
    if (
      labelYear > currentYear ||
      (labelYear === currentYear && labelMonth > currentMonth)
    ) {
      setIsPrevDisabled(false);
    } else {
      setIsPrevDisabled(true);
    }
  }, [labelYear, currentYear, labelMonth, currentMonth]);
  const goToBack = () => {
    if (
      labelYear > currentYear ||
      (labelYear === currentYear && labelMonth > currentMonth)
    ) {
      onNavigate("PREV");
    }
  };

  const goToNext = () => {
    setIsPrevDisabled(false);
    onNavigate("NEXT");
  };

  const goToToday = () => {
    onNavigate("TODAY");
  };

  return (
    <div className="custom-toolbar">
      <div className="custom-toolbar__navigation">
        <Button
          className="custom-toolbar__navigation__today"
          onClick={goToToday}
          aria-label="today"
          variant="outlined"
        >
          Today
        </Button>
        <div className="custom-toolbar__navigation__btn-group">
          <IconButton
            disabled={isPrevDisabled}
            onClick={goToBack}
            aria-label="back"
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton onClick={goToNext} aria-label="next">
            <ChevronRightIcon />
          </IconButton>
        </div>
        <span className="custom-toolbar__navigation__label">{label}</span>
      </div>
      <Link to="/dashboard/create/session">
        <Button className="custom-toolbar__add" variant="contained">
          <AddIcon /> Add new
        </Button>
      </Link>
    </div>
  );
};
