import "./session-fw-card.scss";
import DefaultImg from "assets/my-teaching-default-session-img.png";
import { ReactComponent as StudentsIcon } from "assets/session-card-students.svg";
import { ReactComponent as University } from "assets/mentor-card-university.svg";
import { Button } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const SessionFWCard = ({ session }) => {
  const {
    sessionImg,
    date,
    time,
    title,
    subtitle,
    university,
    description,
    enrolledStudents,
    price,
  } = session;
  return (
    <figure className="session-fw-card">
      <img
        className="session-fw-card__img"
        src={sessionImg || DefaultImg}
        alt=""
        srcset=""
      />
      <Button className="session-fw-card__more-btn">
        <MoreVertIcon />
      </Button>
      <figcaption className="session-fw-card__caption">
        <span className="session-fw-card__date">
          {date} {time}
        </span>
        <h5 className="session-fw-card__title">{title}</h5>
        <h6 className="session-fw-card__subtitle">{subtitle}</h6>
        <p className="session-fw-card__description">{description}</p>
        <span className="session-fw-card__university">
          <University /> {university}
        </span>
        <span className="session-fw-card__students">
          <StudentsIcon /> Students: {enrolledStudents}
        </span>
        <span className="session-fw-card__price">
          Price: <strong>{price}</strong>
        </span>
        <Button className="session-fw-card__view-btn">View session</Button>
      </figcaption>
    </figure>
  );
};

export default SessionFWCard;
