import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowRight } from "assets/home-arrow-right.svg";
import ClassBuddySlider from "components/ClassBuddySlider/ClassBuddySlider";
import SessionCard from "components/SessionCard/SessionCard";
import { useEffect, useState } from "react";
import { getEnrolledSessions } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import settings from "../sliderSettings";
const MyLearningSessions = () => {
  const { auth } = useAuth();
  const { studentId, token } = auth;

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const fetchedSessions = await getEnrolledSessions(studentId, token);
        setSessions(fetchedSessions);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [studentId, token]);

  return (
    <>
      {sessions.length > 0 ? (
        <section id="my-learning">
          <div className="section-heading">
            <h3>My Learning session</h3>
            <Link to="/dashboard/community/my-learning">
              <Button>
                View All
                <ArrowRight />
              </Button>
            </Link>
          </div>
          <ClassBuddySlider settings={settings}>
            {sessions.map((session, index) => (
              <SessionCard key={index} session={session} />
            ))}
          </ClassBuddySlider>
        </section>
      ) : null}
    </>
  );
};

export default MyLearningSessions;
