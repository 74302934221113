import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Autocomplete, TextField, Snackbar, Alert } from "@mui/material";
import sessionSchema from "./schema";
import "./create-document.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
// import { getUniversities } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { createDocument } from "services/api/create";
import { useNavigate } from "react-router-dom";

const successMsg =
  "You've successfully added your session, you'll be redirected";

const CreateDocument = () => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(successMsg);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { auth } = useAuth();
  const { tutorId, token } = auth;
  const navigate = useNavigate();

  // const [universities, setUniversities] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const fetchedUniversities = await getUniversities(token);
  //       if (Array.isArray(fetchedUniversities)) {
  //         setUniversities(fetchedUniversities);
  //       }
  //       console.log(fetchedUniversities);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   })();
  // }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({
    resolver: zodResolver(sessionSchema),
  });

  const handleFileChange = (file) => {
    console.log("file", file);
    if (!file) {
      setError("documentFile", {
        type: "manual",
        message: "A document file is required",
      });
      return;
    }

    // Allowed file MIME types
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    // Max file size (50 MB)
    const maxFileSize = 50 * 1024 * 1024;

    if (!allowedTypes.includes(file.type)) {
      setError("documentFile", {
        type: "manual",
        message: "Invalid file type. Only PDF files are allowed.",
      });
    } else if (file.size > maxFileSize) {
      setError("documentFile", {
        type: "manual",
        message: "File size must not exceed 50MB.",
      });
    } else {
      clearErrors("documentFile");
      return file; // Proceed with this file
      // return blob;
    }

    // If we reach here, the file is invalid for some reason, so we return undefined
    return undefined;
  };

  const onSubmit = async (data) => {
    setIsSubmitted(true);
    const {
      // universityName,
      subjectName,
      documentName,
      description,
      language,
      price,
      documentFile,
    } = data;
    const formData = new FormData();

    formData.append("tutorId", tutorId);
    // formData.append("universityId", universityName);
    formData.append("documentName", documentName);
    formData.append("documentDescription", description);
    formData.append("price", price);
    formData.append("currency", "EUR");
    formData.append("subjectName", subjectName);
    formData.append("language", language);
    // const blob = new Blob([documentFile], { type: documentFile.type });
    formData.append("documentFile", documentFile);
    try {
      // const requestBody = {
      //   tutorId,
      //   universityId: universityName,
      //   documentName,
      //   documentDescription: description,
      //   price,
      //   currency: "EUR",
      //   subjectName,
      //   language,
      //   documentImg: "",
      //   documentFile,
      // };
      const createResponse = await createDocument(token, tutorId, formData);
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      setIsCreated(true);
      const { documentId } = createResponse.data;
      // setTimeout(() => {
      //   navigate(`/dashboard/content/document?documentId=${documentId}`);
      // }, [4000]);
    } catch (err) {
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);
      setIsCreated(false);
      if (err.response.data) {
        setToastMessage(err.response.data);
      } else {
        setToastMessage(err.message);
      }
    }
  };

  return (
    <form
      id="create-document"
      className="create-document form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2>Add new document</h2>
      {/* <Controller
        name="universityName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            options={universities} // Assuming "universities" is your array of objects
            getOptionLabel={(option) => option.name} // Display the university's name
            onChange={(_, option) => field.onChange(option ? option.id : "")}
            // You can also use field.onChange(option?.id || '') for a more concise approach
            renderInput={(params) => (
              <TextField
                {...params}
                label="University"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            // Ensure the selected option is correctly determined
            isOptionEqualToValue={(option, value) => option.id === value}
          />
        )}
      /> */}
      <Controller
        name="subjectName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Subject Name"
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="documentName"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Document Name"
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Description"
            multiline
            rows={4}
            inputProps={{ maxLength: 400 }}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
      <Controller
        name="language"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            id="language"
            className="create-document__field"
            options={["EN", "GR"]}
            onChange={(_, newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Language"
                name="language"
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Price"
            inputProps={{ min: 0 }}
            error={!!error}
            helperText={error ? error.message : null}
            onChange={(e) => {
              if (!Number.isNaN(parseFloat(e.target.value))) {
                field.onChange(parseFloat(e.target.value));
              } else {
                field.onChange(e.target.value);
              }
            }}
          />
        )}
      />
      <Controller
        name="documentFile"
        control={control}
        rules={{ required: "A document file is required" }} // Example of defining a custom error message
        render={({
          field: { onChange, onBlur, name, ref },
          fieldState: { error },
        }) => (
          <label className={`custom-label ${error ? "error" : ""}`}>
            Upload your document:
            <input
              ref={ref}
              name={name}
              type="file"
              onBlur={onBlur}
              onChange={(e) => {
                const file = e.target.files[0];
                handleFileChange(file);
                onChange(file);
              }}
              accept=".pdf,.doc,.docx,.xls,.xlsx"
            />
            {error && <span>{error.message}</span>}
          </label>
        )}
      />
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={isCreated ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <LoadingButton type="submit" loading={isSubmitted} loadingPosition="end">
        Create
      </LoadingButton>
    </form>
  );
};

export default CreateDocument;
