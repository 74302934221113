import { Button } from "@mui/material";
import SectionImg from "assets/landing-page__what-we-offer.png";

const WhatWeOffer = () => {
  return (
    <section id="what-we-offer">
      <img src={SectionImg} alt="laptop" />
      <div className="section-details">
        <span>How to earn money in ClassBuddy ?</span>
        <h3>
          Why tutor on <strong>ClassBuddy</strong> ?
        </h3>
        <h4>
          <strong>ClassBuddy</strong> helps you
          <br />
          become experienced
        </h4>
        <p>
          Our learn provides e-learning solutions for companies, universities
          and individual professionals. It allows users to create courses, and
          provides an integrated learning management system. Its offerings
          include digital course tools, study materials, IT infrastructure and
          other operations.
        </p>
        <Button>Become a member</Button>
      </div>
    </section>
  );
};

export default WhatWeOffer;
