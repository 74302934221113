import { axiosPrivate } from "./axios";

const createSession = async (token, tutorId, body) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Sessions/${tutorId}/add-session/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("response", response);
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to subscribe on tutor"
    );
  }
};

const createDocument = async (token, tutorId, body) => {
  try {
    const response = await axiosPrivate.post(
      `/api/Documents/${tutorId}/add-document/`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("response", response);
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to subscribe on tutor"
    );
  }
};

const createPoll = async (token, body) => {
  try {
    const response = await axiosPrivate.post(`/api/Polls/create-poll`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("response", response);
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to create your poll"
    );
  }
};

export { createDocument, createSession, createPoll };
