import React, { useEffect } from "react";

const NavigationControl = () => {
  useEffect(() => {
    let lastId;
    let currentAnchor;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Get the ID of the section
          const id = entry.target.getAttribute("id");

          // If the section is visible and it's not the same as the last one
          if (id !== lastId) {
            lastId = id;

            // Remove "active" class from the previous anchor if exists
            if (currentAnchor) {
              currentAnchor.classList.remove("active");
            }

            // Set the currentAnchor to the corresponding anchor tag
            currentAnchor = document.querySelector(`a[href="#${id}"]`);

            // Add "active" class to the current anchor tag
            if (currentAnchor) {
              currentAnchor.classList.add("active");
            }
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Observe each section with the corresponding ID
    document.querySelectorAll("section[id]").forEach((section) => {
      observer.observe(section);
    });

    // Clean up the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []); // Run effect only once on component mount

  return (
    <nav>
      <a href="#what-we-offer">What We Offer</a>
      <a href="#how-it-works">How It Works</a>
      <a href="#why-tutor">Why Tutor</a>
      <a href="#top-contributor">Top Contributor</a>
      {/* <a href="#feedback">Feedback</a> */}
      <a href="#learn-more">Learn More</a>
    </nav>
  );
};

export default NavigationControl;
