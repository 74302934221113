import "./document-card.scss";
import DefaultImg from "assets/document-card-default-img.png";
import { ReactComponent as Heart } from "assets/document-card-fav.svg";
import { ReactComponent as Download } from "assets/document-card-download.svg";
import { ReactComponent as Star } from "assets/star.svg";
import { ReactComponent as ArrowRight } from "assets/home-arrow-right.svg";
import { Button, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDateMonthDayYearTime } from "utils/formatting";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useState, useEffect } from "react";
import { subscribeOnDocument } from "services/api/subscribe";
import useAuth from "hooks/useAuth";

const DocumentCard = ({ document, ownDocument = false }) => {
  const {
    documentId,
    documentImg,
    creationDate,
    title,
    content,
    documentDescription,
    totalEnrolledStudents,
    price,
    fileType = "PDF",
    tutorName,
    mentorAvgRate = "0",
    mentorTotalRates = "0",
    documentAvgRate = "0",
    documentTotalRates = "0",
    isSubscribed = false,
  } = document;
  const { auth } = useAuth();
  const { studentId, token } = auth;

  const [isFavorite, setIsFavorite] = useState(isSubscribed);
  const [isFavBtnDisabled, setIsFavBtnDisabled] = useState(false);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/dashboard/content/document?documentId=${documentId}`);
  };

  const favoriteDocument = async () => {
    setIsFavBtnDisabled(true);
    const subscribed = await subscribeOnDocument(documentId, studentId, token);
    if (subscribed) setIsFavBtnDisabled(false);
    if (subscribed.status == 200) {
      setIsFavorite((prev) => !prev);
    }
  };

  return (
    <figure className="document-card">
      <img
        className="document-card__img"
        src={documentImg || DefaultImg}
        alt=""
        srcset=""
      />
      <IconButton
        className="document-card__fav-button"
        disabled={isFavBtnDisabled}
        onClick={favoriteDocument}
      >
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
      <figcaption className="document-card__caption">
        <div className="document-card__date-type">
          <span className="document-card__date-type__date">
            {formatDateMonthDayYearTime(creationDate)}
          </span>
          <span className="document-card__date-type__file-type">
            {fileType}
          </span>
        </div>
        <div className="document-card__info">
          <h5 className="document-card__title">{title}</h5>
          <h6 className="document-card__subtitle">{content}</h6>
          <p className="document-card__description">{documentDescription}</p>
        </div>
        <div className="document-card__mentor">
          Mentor: <strong>{tutorName}</strong>
          <span className="document-card__mentor__rate">
            <Star /> {mentorAvgRate} ({mentorTotalRates})
          </span>
        </div>
        <div className="document-card__rates-downloads">
          <span>
            <Star /> {documentAvgRate} ({documentTotalRates})
          </span>
          <span>
            <Download />
            Downloads: {totalEnrolledStudents}
          </span>
        </div>
        <div className="document-card__actions">
          <span className="document-card__actions__price">{price}</span>
          <Button onClick={handleNavigate}>
            View more <ArrowRight />
          </Button>
        </div>
      </figcaption>
    </figure>
  );
};

export default DocumentCard;
