import SessionCard from "components/SessionCard/SessionCard";
import "./sessions-tab.scss";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import { getTaughtSessions } from "services/api/getContent";

const SessionsTab = ({ value, userId, tutorId }) => {
  const { auth } = useAuth();
  const { token } = auth;

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    if (!tutorId) return;

    (async () => {
      try {
        const sessions = await getTaughtSessions(tutorId, token);
        setSessions(sessions);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token]);

  return (
    <div className="sessions-tab" role="tabpanel" hidden={value !== 3}>
      <h3>Upcoming sessions</h3>
      <div className="sessions-tab__sessions-wrapper">
        {sessions.length > 0 ? (
          sessions.map((session, index) => (
            <SessionCard key={index} session={session} />
          ))
        ) : (
          <h3>No sessions to display</h3>
        )}
      </div>
    </div>
  );
};

export default SessionsTab;
