import { Avatar, Button, Rating } from "@mui/material/";
import "./review-feedback.scss";
const ReviewFeedback = ({
  reviewerImg,
  reviewerName,
  reviewerUniv,
  reviewerRate,
  reviewDate,
  reviewContent,
}) => {
  return (
    <div className="review-feedback">
      <div className="review-feedback__info">
        <figure className="review-feedback__info__user-details">
          {reviewerImg ? (
            <Avatar src={reviewerImg} />
          ) : (
            <Avatar>{reviewerName?.slice(0, 2)}</Avatar>
          )}
          <figcaption>
            <span>
              {reviewerName} - <strong>{reviewerUniv}</strong>
            </span>
            <Rating defaultValue={reviewerRate} readOnly />
          </figcaption>
        </figure>
        <span className="review-feedback__info__date">{reviewDate}</span>
      </div>
      <p className="review-feedback__content">{reviewContent}</p>
      <Button className="review-feedback__report-btn">Report</Button>
    </div>
  );
};

export default ReviewFeedback;
