import "./mentor-card.scss";
import DefaultImg from "assets/mentor-card-default-img.png";
import { ReactComponent as Star } from "assets/star.svg";
import { ReactComponent as Faculty } from "assets/mentor-card-university.svg";
import { ReactComponent as Sessions } from "assets/mentor-card-sessions.svg";
import { ReactComponent as Documents } from "assets/mentor-card-documents.svg";
import { Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { subscribeOnTutor } from "services/api/subscribe";
import useAuth from "hooks/useAuth";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const MentorCard = ({ mentor }) => {
  const {
    firstName,
    lastName,
    tutorId,
    averageRatingContentCount = "0",
    averageRatingContent = "0",
    mentorImg = null,
    bio = null,
    universityName = "University",
    totalRecordedSessions = "0",
    totalUploadedDocuments = "0",
    isSubscribed = false,
  } = mentor;

  const { auth } = useAuth();
  const { studentId, token } = auth;

  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(isSubscribed);
  const [isFavBtnDisabled, setIsFavBtnDisabled] = useState(false);
  const favoriteTutor = async () => {
    setIsFavBtnDisabled(true);
    const subscribed = await subscribeOnTutor(tutorId, studentId, token);
    if (subscribed) setIsFavBtnDisabled(false);
    if (subscribed.status == 200) {
      setIsFavorite((prev) => !prev);
    }
  };
  const handleNavigationToDetails = () => {
    navigate(`/dashboard/community/profile?userId=${tutorId}`);
  };
  return (
    <figure className="mentor-card">
      <img
        className="mentor-card__img"
        src={mentorImg || DefaultImg}
        alt=""
        srcset=""
      />
      <IconButton
        className="mentor-card__fav-button"
        disabled={isFavBtnDisabled}
        onClick={favoriteTutor}
      >
        {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
      <span className="mentor-card__top-rated">Top rated</span>
      <span className="mentor-card__rating">
        <Star />
        {averageRatingContent} {`(${averageRatingContentCount})`}
      </span>
      <figcaption className="mentor-card__caption">
        <div className="mentor-card__info">
          <h5 className="mentor-card__title">{`${firstName} ${lastName}`}</h5>
          <h6 className="mentor-card__summary">{bio}</h6>
          <span className="mentor-card__faculty">
            <span>
              <Faculty />
            </span>
            {universityName}
          </span>
          <div className="mentor-card__contributions">
            <span>
              <Sessions /> {totalRecordedSessions} sessions
            </span>
            <span>
              <Documents /> {totalUploadedDocuments} documents
            </span>
          </div>
        </div>
        <div className="mentor-card__actions">
          <Button onClick={handleNavigationToDetails}>View more</Button>
        </div>
      </figcaption>
    </figure>
  );
};

export default MentorCard;
