import { z } from "zod";

const schema = z
  .object({
    firstName: z.string().min(1, { message: "First name is required" }), // Updated from .nonempty
    lastName: z.string().min(1, { message: "Last name is required" }), // Updated from .nonempty
    universityName: z
      .string()
      .min(1, { message: "University name is required" }),
    email: z
      .string()
      .email({ message: "Invalid email address" })
      .min(1, { message: "Email is required" }), // Updated from .nonempty
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" })
      .min(1, { message: "Password is required" }), // Updated, but you might not need this line since .min(8) already ensures the string is not empty
    confirmPassword: z
      .string()
      .min(1, { message: "Confirm Password is required" }), // Updated from .nonempty
    agreeToTerms: z.boolean().refine((val) => val, {
      message: "You must agree to the terms and conditions",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });
export default schema;
