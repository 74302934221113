import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CBAreaChart from "components/CBAreaChart/CBAreaChart";
import "./total-balance.scss";
const TotalBalance = () => {
  const data = [
    { day: "Mon", value: "250" },
    { day: "Tue", value: "200" },
    { day: "Wed", value: "350" },
    { day: "Thur", value: "100" },
    { day: "Fri", value: "500" },
  ];
  const xAxisName = "day";
  const yAxisName = "value";

  return (
    <div className="credit-control__total-balance box large">
      <header>
        <h4>Total Balance</h4>
        <Autocomplete
          disablePortal
          defaultValue="Weakly"
          options={["Weakly", "Monthly"]}
          renderInput={(params) => <TextField {...params} />}
        />
      </header>
      <CBAreaChart data={data} xAxisName={xAxisName} yAxisName={yAxisName} />
    </div>
  );
};

export default TotalBalance;
