import "./landing.scss";
import Header from "./sections/Header";
import Hero from "./sections/Hero";
import NavigationControl from "./sections/NavigationControl";
import HowItWorks from "./sections/HowItWorks";
import WhyTutor from "./sections/WhyTutor";
import TopContributor from "./sections/TopContributor";
import FeedBack from "./sections/FeedBack";
import LearnMore from "./sections/LearnMore";
import WhatWeOffer from "./sections/WhatWeOffer";
const Landing = () => {
  return (
    <div id="landing">
      <div className="heading-container">
        <Header />
        <Hero />
      </div>
      <div className="container">
        <NavigationControl />
        <WhatWeOffer />
        <HowItWorks />
        <WhyTutor />
        <TopContributor />
        {/* <FeedBack /> */}
        <LearnMore />
      </div>
    </div>
  );
};

export default Landing;
