import { Button } from "@mui/material";
import usersImg from "assets/landing__users-img.png";
const LearnMore = () => {
  return (
    <section id="learn-more" className="learn-more">
      <div className="learn-more__details">
        <h5>
          Learn move and earn <br />
          more &#x1F60D;
        </h5>
        <p>Over 100,000 students join us monthly</p>
        <div className="users-imgs">
          <img src={usersImg} />
          <strong>and others</strong>
        </div>
      </div>
      <Button>Get your lessons now!</Button>
    </section>
  );
};

export default LearnMore;
