import SectionImg from "assets/landing-page__what-to-be-contributor.png";
import { ReactComponent as UploadDocument } from "assets/landing__document-upload-icon.svg";
import { ReactComponent as ApproveDocument } from "assets/landing__document-approve-icon.svg";
import { ReactComponent as ViewStudent } from "assets/landing__account-view-icon.svg";
import { ReactComponent as EarnMoney } from "assets/landing__money-icon.svg";

const TopContributor = () => {
  return (
    <section id="top-contributor">
      <div className="section-details">
        <span>Earn Money by Selling Study Documents!</span>
        <h3>
          Want to be the one of our TOP <strong>contributors</strong> ?
        </h3>
        <p>
          Earn up to $5,000 USD a month. We share your docs with 10’s of
          millions of students. You get up to $10 every time a student views
          one.
        </p>
        <ul>
          <li>
            <UploadDocument />
            <span>
              <strong>Upload Document</strong>
              Upload class notes, old homework, exam/quiz prep, zip files ...
              etc.
            </span>
          </li>
          <li>
            <ApproveDocument />
            <span>
              <strong>Get Approved</strong>
              Our system reviews and approves uploaded documents for quality.{" "}
            </span>
          </li>
          <li>
            <ViewStudent />
            <span>
              <strong>Viewed by student</strong>
              10’s of millions of students search and have access to your
              document.
            </span>
          </li>
          <li>
            <EarnMoney />
            <span>
              <strong>Earn money</strong>
              You earn up to 10$ every time a student views your document.{" "}
            </span>
          </li>
        </ul>
      </div>
      <img src={SectionImg} alt="books, people and income" />
    </section>
  );
};

export default TopContributor;
