import "./events-summary.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "hooks/useAuth";
import { getEnrolledSessions } from "services/api/getContent";
import { formatDateMonthDayYearTime } from "utils/formatting";
const EventsSummary = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const [enrolledSessions, setEnrolledSessions] = useState([]);

  const viewAllHandler = () => {
    navigate("/dashboard/community/calendar");
  };

  useEffect(() => {
    (async () => {
      try {
        const fetchedEnrolledSessions = await getEnrolledSessions(
          studentId,
          token
        );
        setEnrolledSessions(fetchedEnrolledSessions.slice(0, 5));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [studentId, token]);

  return (
    <div className="sessions-summary">
      <div className="sessions-summary__heading">
        <span>Upcoming</span>
        <Button onClick={viewAllHandler}>View all</Button>
      </div>
      <div className="sessions-list">
        {enrolledSessions.length === 0 ? (
          <h5>No upcoming sessions</h5>
        ) : (
          enrolledSessions.map((session) => (
            <div className="sessions-list__event">
              <div className="sessions-list__event__content">
                <span className="sessions-list__event__time">
                  {formatDateMonthDayYearTime(session.sessionDate)}
                </span>
                <div className="sessions-list__event__description">
                  <span>{session.sessionName}</span>
                  <span>{session.sessionDescription}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default EventsSummary;
