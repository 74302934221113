import React, { useState, useRef, useEffect } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import "./session-preview-card.scss";
import MyVideo from "./test.mp4";
import { Button, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { formatDateMonthDayYearTime } from "utils/formatting";
import { Link } from "react-router-dom";
import { enrollInSession, subscribeOnSession } from "services/api/subscribe";
import useAuth from "hooks/useAuth";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUserContext } from "context/UserContext";

const SessionPreviewCard = ({
  sessionDate,
  sessionDuration,
  zoomMeetingLink,
  price,
  sessionId,
  isSessionSubscribed,
}) => {
  const { auth } = useAuth();
  const { studentId, token } = auth;
  const { userState, setUserState } = useUserContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFavorite, setIsFavorite] = useState(isSessionSubscribed);
  const [hasEnrolled, setHasEnrolled] = useState(zoomMeetingLink.length > 0);
  const [isFavBtnLoading, setIsFavBtnLoading] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [sessionLink, setSessionLink] = useState(zoomMeetingLink);

  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const favoriteSession = async () => {
    setIsFavBtnLoading(true);
    try {
      await subscribeOnSession(sessionId, studentId, token);
      setIsFavBtnLoading(false);
      setIsFavorite((prev) => !prev);
    } catch (error) {
      setIsFavBtnLoading(false);
      console.error(error);
    }
  };

  const handleEnrollInSession = async () => {
    setIsEnrolling(true);

    try {
      const zoomLink = await enrollInSession(sessionId, studentId, token);
      setSessionLink(zoomLink);
      setHasEnrolled(true);
      setUserState((prevState) => ({
        ...prevState,
        userTotalBalance: userState.userTotalBalance - price,
      }));
      setIsEnrolling(false);
    } catch (error) {
      console.error(error);
      setIsEnrolling(false);
    }
  };

  useEffect(() => {
    setIsFavorite(isSessionSubscribed);
  }, [isSessionSubscribed]);

  return (
    <div className="session-preview-card">
      <div className="video-player">
        <video ref={videoRef}>
          <source src={MyVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <IconButton
          onClick={togglePlayPause}
          size="large"
          aria-label={isPlaying ? "Pause" : "Play"}
        >
          {isPlaying ? (
            <PauseIcon fontSize="large" />
          ) : (
            <PlayArrowIcon fontSize="large" />
          )}
        </IconButton>
      </div>
      <span className="session-price">{price}$</span>
      <div className="session-schedule">
        <h5>Schedule</h5>
        <div className="session-date">
          {formatDateMonthDayYearTime(sessionDate)} - {sessionDuration} minutes
        </div>
        <div className="session-control">
          {sessionLink.length > 0 ? (
            <Button>
              <Link to={sessionLink}>Go to your room</Link>
            </Button>
          ) : (
            <LoadingButton
              disabled={userState.userTotalBalance < price}
              loading={isEnrolling}
              onClick={handleEnrollInSession}
              className="session-enroll"
            >
              Enroll now
            </LoadingButton>
          )}
          <LoadingButton loading={isFavBtnLoading} onClick={favoriteSession}>
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default SessionPreviewCard;
