import { TextField, Autocomplete } from "@mui/material";
import React from "react";
import ProfileFormValidation from "./ProfileFormValidation";
export function BasicInformation({
  formData,
  errors,
  handleChange,
  setFormData,
}) {
  const gender = ["male","female","prefer not to say"]
  return (
    <div className="group">
      <h3 className="medium--18">Basic Information:</h3>
      <TextField
        name="firstName"
        label="First Name"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors.firstName}
        value={formData.firstName}
        onChange={handleChange("firstName")}
      />
      {errors.firstName && <span>{errors.firstName}</span>}
      <TextField
        name="lastName"
        label="Last Name"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChange("lastName")}
        error={errors.lastName}
        value={formData.lastName}
      />
      {errors.lastName && <span>{errors.lastName}</span>}
      <TextField
        name="email"
        label="Email"
        type="email"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleChange("email")}
        error={errors.email}
        value={formData.email}
      />
      {errors.email && <span>{errors.email}</span>}
      {/* <DatePicker
        name="dateOfBirth"
        label="Date Picker"
        format="YYYY/MM/DD"
        defaultValue={dayjs("2022-04-17")}
        fullWidth={true}
        onChange={handleChange("dateOfBirth")}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors.dateofBirth}
        value={formData.dateofBirth}
      /> */}
      <TextField
        name="dateOfBirth"
        label="Date Of Birth"
        type="date"
        format="YYYY-MM-DD"
        placeholder=""
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        defaultValue={formData.dateofBirth}
        error={errors.dateofBirth}
        value={formData.dateofBirth}
        onChange={handleChange("dateOfBirth")}
      />
      {errors.dateOfBirth && <span>{errors.dateOfBirth}</span>}

      <TextField
        name="phoneNumber"
        label="Phone number"
        type="phone"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors.phoneNumber}
        value={formData.phoneNumber}
        onChange={handleChange("phoneNumber")}
      />
      {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
      <TextField
        name="citizenship"
        label="Nationality"
        type="text"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        error={errors.citizenship}
        value={formData.citizenship}
        onChange={handleChange("citizenship")}
      />
      <Autocomplete
        name="gender"
        type="string"
        fullWidth={true}
        InputLabelProps={{
          shrink: true,
        }}
        options={gender}
        value={formData.gender}
        defaultValue={"Gender"}
        onChange={(event, newValue) => {
          setFormData((prevFormData) => ({
            ...prevFormData,
            gender: newValue,
          }));
        }}
        renderInput={(params) => <TextField {...params} label="Gender" />}
      />
    </div>
  );
}
