import { ReactComponent as StarIcon } from "../../../../assets/star.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/home-arrow-right.svg";
import SessionDefaultImg from "../../../../assets/profile__session-default-img.jpg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDistance } from "date-fns";
import { useEffect } from "react";
import { formatDateMonthDayYearTime } from "utils/formatting";

const ProfileSessionCard = ({ upComingSession }) => {
  const navigate = useNavigate();
  const {
    sessionId,
    sessionDate,
    sessionName,
    // sessionSubTitle,
    sessionDescription,
    sessionImg,
    sessionMentor,
    averageRating,
    // sessionTotalRate,
    price,
  } = upComingSession;

  return (
    <figure className="overview-tab__main__session__card">
      <div className="img-wrapper">
        <img src={sessionImg || SessionDefaultImg} alt="" />
      </div>
      <figcaption>
        <span className="overview-tab__main__session__card__date">
          {formatDateMonthDayYearTime(sessionDate)}
        </span>
        <h4>{sessionName}</h4>
        {/* <h5>{sessionSubTitle}</h5> */}
        <p>{sessionDescription}</p>
        <h6>
          Mentor: <strong>{sessionMentor}</strong> <StarIcon /> {averageRating}{" "}
          {/* ({sessionTotalRate}) */}
        </h6>
        <div className="overview-tab__main__session__card__control">
          <span>{price} Euro</span>
          <Button
            onClick={() =>
              navigate(`/dashboard/content/session?sessionId=${sessionId}`)
            }
          >
            More details <ArrowRight />
          </Button>
        </div>
      </figcaption>
    </figure>
  );
};

export default ProfileSessionCard;
