import { axiosPrivate } from "./axios";

// Service to get recommended documents
const getRecommendedDocuments = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Documents/${studentId}/recommended-documents`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch recommended documents"
    );
  }
};

// Service to get recommended sessions
const getRecommendedSessions = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Sessions/${studentId}/recommended-sessions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch recommended sessions"
    );
  }
};

// Service to get enrolled sessions
const getEnrolledSessions = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Students/${studentId}/enrolled-sessions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch enrolled sessions"
    );
  }
};

// Service to get enrolled documents
const getEnrolledDocuments = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Students/${studentId}/enrolled-documents`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch enrolled documents"
    );
  }
};

// Service to get popular sessions
const getPopularSessions = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Sessions/popular-sessions/${studentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch popular sessions"
    );
  }
};

// Service to get popular documents
const getPopularDocuments = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Documents/popular-documents/${studentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch popular documents"
    );
  }
};

// Service to get popular documents' tutors
const getPopularDocumentsTutors = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Documents/popular-tutors/${studentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Service to get popular documents' tutors
const getPopularSessionsTutors = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Sessions/popular-tutors/${studentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Service to get taught documents' tutors
const getTaughtDocuments = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get("/api/Tutors/teached-documents", {
      params: {
        tutorId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// Service to get taught documents' tutors
const getTaughtSessions = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get("/api/Tutors/teached-sessions", {
      params: {
        tutorId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUniversities = async () => {
  try {
    const response = await axiosPrivate.get(`/api/University`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to subscribe on tutor"
    );
  }
};

// Service to get favorite sessions
const getFavoriteSessions = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Students/${studentId}/favorite-sessions`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch favorite sessions"
    );
  }
};

// Service to get favorite documents
const getFavoriteDocuments = async (studentId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Students/${studentId}/favorite-documents`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch favorite documents"
    );
  }
};

// Service to get favorite documents
const getUserStats = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/ClassBuddyUsers/${tutorId}/homepagestats`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch favorite documents"
    );
  }
};

// Service to get recent polls
const getRecentPolls = async (userId, token) => {
  try {
    const response = await axiosPrivate.get(`/api/Polls/get-recent-polls`, {
      params: {
        voterId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch recent polls"
    );
  }
};

// Service to get trending polls
const getTrendingPolls = async (userId, token) => {
  try {
    const response = await axiosPrivate.get(`/api/Polls/get-recent-polls`, {
      params: {
        voterId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch trending polls"
    );
  }
};

// Service to get session details
const getSessionDetails = async (sessionId, token) => {
  debugger;
  try {
    const response = await axiosPrivate.get(`/api/Sessions/${sessionId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch session details"
    );
  }
};

// Service to get tutor's session rating stats
const getTutorSessionsRatingStats = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Sessions/${tutorId}/get-sessions-rating-stats`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        "Failed to fetch tutor's session rating stats"
    );
  }
};

// Service to get tutor's session reviews
const getTutorSessionsReviews = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Sessions/${tutorId}/get-sessions-reviews`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch tutor's session reviews"
    );
  }
};

// Service to get tutor's document rating stats
const getTutorDocumentsRatingStats = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Documents/${tutorId}/get-documents-rating-stats`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message ||
        "Failed to fetch tutor's document rating stats"
    );
  }
};

// Service to get tutor's document reviews
const getTutorDocumentsReviews = async (tutorId, token) => {
  try {
    const response = await axiosPrivate.get(
      `/api/Documents/${tutorId}/get-documents-reviews`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch tutor's session reviews"
    );
  }
};

export {
  getRecommendedSessions,
  getRecommendedDocuments,
  getEnrolledSessions,
  getEnrolledDocuments,
  getPopularSessions,
  getPopularDocuments,
  getPopularSessionsTutors,
  getPopularDocumentsTutors,
  getTaughtDocuments,
  getTaughtSessions,
  getUniversities,
  getFavoriteSessions,
  getFavoriteDocuments,
  getUserStats,
  getRecentPolls,
  getTrendingPolls,
  getSessionDetails,
  getTutorSessionsRatingStats,
  getTutorSessionsReviews,
  getTutorDocumentsRatingStats,
  getTutorDocumentsReviews,
};
