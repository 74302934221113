import CBAccordion from "components/ui/CBSearch/CBAccordion/CBAccordion";
import "./faq.scss";
import CBSearch from "components/ui/CBSearch/CBSearch";
import { useState } from "react";

const questions = [
  {
    question: "Do I get a certificate for participation?",
    answer:
      "No, upon successful course completion you will be eligible to get more offers",
  },
  {
    question: "Do I get a certificate for participation?",
    answer:
      "No, upon successful course completion you will be eligible to get more offers",
  },
  {
    question: "Do I get a certificate for participation?",
    answer:
      "No, upon successful course completion you will be eligible to get more offers",
  },
  {
    question: "Do I get a certificate for participation?",
    answer:
      "No, upon successful course completion you will be eligible to get more offers",
  },
];
const FAQ = () => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <div id="faq" className="faq">
      <header>
        <h3>Frequently asked questions</h3>
        <CBSearch searchValue={searchValue} setSearchValue={setSearchValue} />
      </header>
      <div className="faq__wrapper">
        {questions.map(({ question, answer }) => (
          <CBAccordion summary={question} details={answer} />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
