import { axiosPrivate } from "./axios";

// Service to get user data
const getUserData = async (userId, token) => {
  try {
    const response = await axiosPrivate.get(`/api/ClassBuddyUsers/${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch user data"
    );
  }
};

export { getUserData };
