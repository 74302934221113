import React, { useState } from "react";
import { Button, Rating, TextField, Avatar } from "@mui/material";
import { ReactComponent as StarIcon } from "assets/details__star.svg";
import { ReactComponent as ReviewsIcon } from "assets/details__reviews.svg";
import { ReactComponent as DownloadsIcon } from "assets/details__downloads.svg";
import { ReactComponent as StudentsIcon } from "assets/details__students.svg";
import { ReactComponent as DiagonalArrow } from "assets/details__arrow-diagonal.svg";
import DefaultImg from "assets/details__document-default-img.png";
import { getNameInitials } from "utils/formatting";
import PDFViewer from "components/PDFViewer";
import PDFFile from "./progit.pdf";
import "./document-details.scss";
import SubmitReview from "components/SubmitReview";
import { useSearchParams } from "react-router-dom";

const documents = [
  {
    img: null,
    title: "Javascript",
    description:
      "statement or statements that pertain to a stated that pertain to a stated period to",
    tutor: "Aleksei Badianov",
    avgReviews: 4.8,
    totalReviews: 70,
    downloadsRate: 30,
    price: "Free",
  },
];

const reviews = [
  {
    userName: "John Doe",
    rate: 4.5,
    date: "2 weeks ago",
    reviewContent:
      "stating I think it is a suitable title for the course. It was a very interesting course for me. Thank you.",
  },
];
const DocumentDetails = () => {
  const [searchParams] = useSearchParams();
  const documentId = searchParams.get("documentId");
  const tutorId = "";
  return (
    <div className="document-details">
      <div className="document-details__info">
        <div className="document-details__info__general">
          <span className="document-date">November 28, 2023</span>
          <h4 className="document-title">Accounting principles (01)</h4>
          <p className="document-description">
            Accounting Competency EXAM Sample EXAM. 2. The financial statement
            or statements that pertain to a stated period of time is (are) the -
            PDF
          </p>
          <div className="document-rate-and-downloads">
            <span className="document-rate">4.8 (570 Ratings),</span>
            <span className="document-downloads">
              800 downloaded this document.
            </span>
          </div>
          <h4>
            Uploaded by <Button>Bol bol</Button>
          </h4>
          <span className="document-price">35 Cr</span>
        </div>
        <div className="document-details__info__tutor">
          <h4>tutor</h4>
          <div className="document-details__info__tutor__card">
            <div className="document-details__info__tutor__card__row">
              <Avatar>AB</Avatar>
              <div className="tutor-info">
                <span>
                  <StarIcon /> 4.7 Tutor Rating
                </span>
                <span>
                  <ReviewsIcon /> 1122 Reviews
                </span>
                <span>
                  <StudentsIcon /> 2231 Students
                </span>
              </div>
            </div>
            <h6>Aleksei Badianov</h6>
            <p>
              Aleksei is renowned for his expertise in product management, with
              a particular focus on logistics, e-commerce and inventory
              management. He is an active field of dynamic landscape of
              technology and AI.
            </p>
            <Button>
              view tutor profile
              <DiagonalArrow />
            </Button>
          </div>
          <div className="document-details__info__tutor__documents">
            <h4>
              More documents by <Button>Aleksei Badianov</Button>
            </h4>
            <div className="documents-column">
              {documents.map((document) => (
                <DocumentDetailsCard document={document} />
              ))}
            </div>
          </div>
        </div>
        {/* <SubmitReview
          reviews={reviews}
          type="document"
          contentId={documentId}
          tutorId={tutorId}
        /> */}
      </div>
      <div className="pdf-wrapper">
        <PDFViewer file={PDFFile} />
      </div>
    </div>
  );
};

export default DocumentDetails;

const DocumentDetailsCard = ({ document }) => {
  const {
    img,
    title,
    description,
    tutor,
    avgReviews,
    totalReviews,
    downloadsRate,
    price,
  } = document;
  return (
    <div className="document-details-card">
      <figure>
        <div className="img-container">
          <img src={img || DefaultImg} />
        </div>
        <figcaption>
          <Button>{title}</Button>
          <p>{description}</p>
          <span>
            Mentor: <strong>{tutor}</strong>
          </span>
        </figcaption>
      </figure>
      <div className="document-details-info">
        <span className="document-details-info__reviews">
          <StarIcon /> {avgReviews} {totalReviews}
        </span>
        <span className="document-details-info__rate">
          <DownloadsIcon /> Downloads: {downloadsRate}
        </span>
        <span className="document-details-info__price">{price}</span>
      </div>
    </div>
  );
};

const ReviewsDetailsCard = ({ review }) => {
  const { userName, rate, date, reviewContent } = review;
  return (
    <div className="document-details-review">
      <figure>
        <Avatar>{getNameInitials(userName)}</Avatar>
        <figcaption>
          <span>
            <strong>{userName}</strong> {date}
          </span>
          <Rating value={rate} readOnly />
        </figcaption>
      </figure>
      <p>{reviewContent}</p>
    </div>
  );
};
