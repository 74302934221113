import SectionImg from "assets/landing-page__how-it-works.png";
import { ReactComponent as Person } from "assets/landing__person-icon.svg";
import { ReactComponent as Calendar } from "assets/landing__calendar-icon.svg";
import { ReactComponent as Laptop } from "assets/landing__laptop-icon.svg";

const HowItWorks = () => {
  return (
    <section id="how-it-works">
      <div className="section-details">
        <span>How i study in ClassBuddy ?</span>
        <h3>
          Here’s how it <strong>works</strong>
        </h3>
        <p>
          Want to learn from the comfort of your home? Our online session
          tutoring is perfect for you. Connect with a verified tutor. Tutors can
          cover all subjects and grade levels.
        </p>
        <ul>
          <li>
            <Person />
            <span>
              <strong>Find a session</strong>
              Choose your ideal session from over 10,000 qualified tutors.
            </span>
          </li>
          <li>
            <i>
              <Calendar />
            </i>
            <span>
              <strong>Enroll a session</strong>
              Select a session and add it to your calendar.
            </span>
          </li>
          <li>
            <i>
              <Laptop />
            </i>
            <span>
              <strong>Start learning</strong>
              Simple as that, you’re learning a Subject.{" "}
            </span>
          </li>
        </ul>
      </div>
      <img src={SectionImg} alt="laptop" />
    </section>
  );
};

export default HowItWorks;
