import { createContext, useState, useContext, useEffect } from "react";
import { useLocalStorage } from "hooks/useLocalStorage";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userState, setUserState] = useLocalStorage("ClassBuddyUser", {});

  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}
