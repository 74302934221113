import "@fontsource/comfortaa";
import "@fontsource/poppins";
import "./nav-bar.scss";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Avatar, IconButton } from "@mui/material";
import { Menu, InputAdornment, Select, MenuItem, Chip } from "@mui/material/";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserStats } from "services/api/getContent";
import useAuth from "hooks/useAuth";
import { useUserContext } from "context/UserContext";

function NavBar() {
  const [searchBarCategory, setSearchBarCategory] = useState("sessions");
  const [searchBarValue, setSearchBarValue] = useState("");
  const [creditsValue, setCreditsValue] = useState(0);
  const [userLetters, setUserLetters] = useState("MH");
  const [userMenu, setUserMenu] = useState(null);
  const { userState, setUserState } = useUserContext();
  const { auth } = useAuth();
  const { tutorId, token } = auth;

  useEffect(() => {
    if (userState.tutorId) return;
    (async () => {
      try {
        const fetchedUserStats = await getUserStats(tutorId, token);
        setUserState(fetchedUserStats);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tutorId, token, userState]);

  const settings = ["Profile", "Dashboard", "Logout"];
  const navigate = useNavigate();
  const handleCreditClick = () => {};
  const handleNavigateToHomeScreen = () => navigate("/dashboard");
  return (
    <header className="classbuddy-navigation-bar">
      <Link to="/dashboard">
        <h1>
          Class<span>Buddy</span>
        </h1>
      </Link>
      <div className="classbuddy-navigation-bar__search-field">
        <TextField
          placeholder="Search all sessions"
          value={searchBarValue}
          onChange={(event) => setSearchBarValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Select
          value={searchBarCategory}
          onChange={(event) => setSearchBarCategory(event.target.value)}
        >
          <MenuItem value={"sessions"}>Sessions</MenuItem>
          <MenuItem value={"documents"}>Documents</MenuItem>
        </Select>
      </div>
      <div className="classbuddy-navigation-bar__controls">
        <Chip
          className="classbuddy-navigation-bar__credit"
          label={`${userState.userTotalBalance || 0} Euro`}
          onClick={handleCreditClick}
        />
        <IconButton className="classbuddy-navigation-bar__notification">
          <NotificationsIcon />
        </IconButton>
        <Box
          sx={{ flexGrow: 0 }}
          className="classbuddy-navigation-bar__user-menu"
        >
          <Tooltip title="Open settings">
            <IconButton
              onClick={(event) => setUserMenu(event.currentTarget)}
              sx={{ p: 0 }}
            >
              <Avatar>
                {userState.userFirstName?.slice(0, 1)}
                {userState.userLastName?.slice(0, 1)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={userMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(userMenu)}
            onClose={() => setUserMenu(null)}
          >
            {settings.map((setting) => (
              <MenuItem
                key={setting}
                onClick={() => {
                  setUserMenu(null);
                  if (setting === "Profile")
                    navigate("/dashboard/community/profile");
                  if (setting === "Dashboard") navigate("/dashboard");
                  if (setting === "Logout") {
                    localStorage.removeItem("ClassBuddyAuth");
                    navigate("/auth/sign-in");
                  }
                }}
              >
                <span textAlign="center">{setting}</span>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
    </header>
  );
}

export default NavBar;
