import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "services/api/axios";
import { zodResolver } from "@hookform/resolvers/zod";
import schema from "./schema";
import { ReactComponent as WarningIcon } from "assets/warning-icon.svg";
import {
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import "./sign-up.scss";
import { Link, useNavigate } from "react-router-dom";
import { getUniversities } from "services/api/getContent";
const SIGN_UP_URL = "/api/ClassBuddyUsers/register";
const successMsg = "You've successfully registered, please check your email";

const SignUp = () => {
  const [universities, setUniversities] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState(successMsg);
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const fetchedUniversities = await getUniversities();
        if (Array.isArray(fetchedUniversities)) {
          setUniversities(fetchedUniversities);
        }
        console.log(fetchedUniversities);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsToastOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    const { firstName, lastName, email, password, universityName } = data;
    setIsSubmitted(true);
    try {
      const response = await axios.post(
        SIGN_UP_URL,
        JSON.stringify({
          firstName,
          lastName,
          email,
          password,
          universityId: universityName,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setIsRegistered(true);
      setToastMessage(successMsg);
      setIsToastOpen(true);
      setIsSubmitted(false);

      setTimeout(() => {
        setIsToastOpen(false);
        navigate("/auth/sign-in");
      }, [1000]);
    } catch (err) {
      setIsSubmitted(false);
      setIsRegistered(false);
      if (err.response.data) {
        setToastMessage(err.response.data);
      } else {
        setToastMessage(err.message);
      }
      setIsToastOpen(true);
    }
  };

  return (
    <div className="sign-up">
      <h3>Sign Up</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", columnGap: "15px" }}>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ""}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : ""}
              />
            )}
          />
        </div>
        <Controller
          name="universityName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              options={universities} // Assuming "universities" is your array of objects
              getOptionLabel={(option) => option.name} // Display the university's name
              onChange={(_, option) => field.onChange(option ? option.id : "")}
              // You can also use field.onChange(option?.id || '') for a more concise approach
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="University"
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              // Ensure the selected option is correctly determined
              isOptionEqualToValue={(option, value) => option.id === value}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              margin="normal"
              fullWidth
              label="Email Address"
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Password"
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Confirm Password"
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword ? errors.confirmPassword.message : ""
              }
            />
          )}
        />
        <Controller
          name="agreeToTerms"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              }
              label={
                <span>
                  I agree to the{" "}
                  <Link to="/dashboard/terms-and-conditions">
                    terms and conditions
                  </Link>
                </span>
              }
            />
          )}
        />
        {errors.agreeToTerms && (
          <p className="error-msg">
            <WarningIcon /> {errors.agreeToTerms.message}
          </p>
        )}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="submit-btn"
          loading={isSubmitted}
          loadingPosition="end"
        >
          Sign Up
        </LoadingButton>
        <p>
          Or you've an account? <Link to="/auth/sign-in">sign in</Link>
        </p>
      </form>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={isRegistered ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignUp;
