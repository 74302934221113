import { Chip } from "@mui/material";
import { ReactComponent as AttendanceIcon } from "../../../../assets/profile__attendance-icon.svg";
import { ReactComponent as RocketIcon } from "../../../../assets/profile__rocket-icon.svg";
import { ReactComponent as StarsIcon } from "../../../../assets/profile__stars-icon.svg";
import IntroDefaultThumbnail from "../../../../assets/profile__video-intro-default.png";

const VideoIntroductionCard = ({
  VideoIntroductionData: {
    videoThumbnail,
    totalTeachingMins,
    numberOfSessionsCompleted,
    avgAttendance,
    discussedTopics,
  },
}) => {
  return (
    <figure className="overview-tab__video-intro">
      <h3>Video introduction</h3>
      <div className="img-wrapper">
        <img src={videoThumbnail || IntroDefaultThumbnail} alt="" />
      </div>
      <figcaption>
        <h4>Community statistics</h4>
        <div className="statistics-wrapper">
          <div className="statistic">
            <i>
              <RocketIcon />
            </i>
            <span>
              <strong>{totalTeachingMins} mins</strong> Total teaching time
            </span>
          </div>
          <div className="statistic">
            <i>
              <StarsIcon />
            </i>
            <span>
              <strong>{numberOfSessionsCompleted}</strong> Sessions completed
            </span>
          </div>
          <div className="statistic">
            <i>
              <AttendanceIcon />
            </i>
            <span>
              <strong>{avgAttendance}</strong> Avg attendance
            </span>
          </div>
        </div>
        <div className="impact">
          <h4>Top areas of impact</h4>
          <p>Highly discussed topics during sessions</p>
          <div className="topics">
            {discussedTopics.map((topic) => (
              <Chip label={topic} />
            ))}
          </div>
        </div>
      </figcaption>
    </figure>
  );
};

export default VideoIntroductionCard;
