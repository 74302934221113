import { Button } from "@mui/material";
import "./get-more.scss";
import { ReactComponent as Bank } from "assets/home-get-more-bank.svg";
import AddIcon from "@mui/icons-material/Add";
import personImg from "assets/home-get-more-person.png";
import groupImg from "assets/home-get-more-group.png";

const GetMore = () => {
  return (
    <section id="get-more" className="get-more">
      <h3>Get more from ClassBuddy</h3>
      <div className="get-more__container">
        <figure className="get-more__left-section">
          <img src={personImg} alt="" />
          <figcaption>
            <strong>
              Work with us and git favorable remuneration , flexible work
              schedule development opportunities
            </strong>
            Providing you with Live, Interactive and Self-paced sessions
            <Button>Become a mentor</Button>
          </figcaption>
        </figure>
        <div className="get-more__right-section">
          <div className="get-more__right-section__top">
            <span className="get-more__right-section__top__bank">
              <Bank />
            </span>
            <strong>Increase your income with us</strong>
            <p>
              Become a versatile leader in business, tech or healthcare by
              gaining professional qualifications from The Open University.
            </p>
          </div>
          <figure>
            <img src={groupImg} alt="" />
            <figcaption>
              Start now and add create sessions and upload documents
              <Button>
                <AddIcon /> Add new
              </Button>
            </figcaption>
          </figure>
        </div>
      </div>
    </section>
  );
};

export default GetMore;
